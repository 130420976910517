import {type} from "../abstract/Model.js";
import moment from "moment";

export default function ExpirationMixin(expiresIn, unit, options = {}) {
    options = {
        insertOnly: true,
        required: true,
        defaultOnly: true,
        ...options
    };

    return class {
        expireAt = {
            [type]: Date,
            required: options.required,
            default() {
                return moment().add(expiresIn, unit).toDate();
            },
            defaultOnly: options.defaultOnly,
            insertOnly: options.insertOnly,
            ttl: 0
        }
    }
}
