export default class FieldsHelper {
    static get fields() {
        return [
            {
                name: `Text Line|Ligne de texte`,
                type: `text`,
                glyph: `fas fa-input-text`,
                multilingual: true,
                group: `any-text`
            },
            {
                name: `Text Area|Bloc de texte`,
                type: `textarea`,
                glyph: `fas fa-align-left`,
                multilingual: true,
                group: `any-text`
            },
            {
                name: `HTML`,
                type: `html`,
                glyph: `fas fa-block-quote`,
                multilingual: true,
                group: `any-text`
            },
            {
                name: `Select|Choix`,
                type: `select`,
                glyph: `fas fa-list-dropdown`,
                options: true,
                group: `any-text`
            },
            {
                name: `Date Time|Date et heure`,
                type: `datetime`,
                glyph: `fas fa-calendar-clock`,
                date: true,
                group: `any-text`,
                formats: [
                    { value: `moment|llll`, label: `Thu, Sep 4, 1986 8:30 PM|Jeu 4 sept 1986 20:30` },
                    { value: `moment|LLLL`, label: `Thursday, September 4, 1986 8:30 PM|Jeudi, 4 septembre 1986 20:30` },
                    { value: `moment|lll`, label: `Sep 4, 1986 8:30 PM|4 sept 1986 20:30` },
                    { value: `moment|LLL`, label: `September 4, 1986 8:30 PM|4 septembre 1986 20:30` },
                    { value: `moment|ll`, label: `Sep 4, 1986|4 sept 1986` },
                    { value: `moment|LL`, label: `September 4, 1986|4 septembre 1986` },
                    { value: `moment|L`, label: `04/15/1986|15/04/1986` },
                    { value: `moment|LT`, label: `Local Time|Heure locale` },
                    { value: `moment|LTS`, label: `Local Time (seconds)|Heure locale (secondes)` },
                    { value: `moment|dddd`, label: `Week Day|Jour de la semaine` },
                    { value: `moment|MMMM`, label: `Month|Mois` },
                    { value: `moment|DD`, label: `Day|Jour` },
                    { value: `moment|YYYY`, label: `Year|Année` },
                ]
            },
            {
                name: `Date Only|Date seulement`,
                type: `date`,
                glyph: `fas fa-calendar-days`,
                date: true,
                group: `any-text`,
                formats: [
                    { value: `moment|ll`, label: `Sep 4, 1986|4 sept 1986` },
                    { value: `moment|LL`, label: `September 4, 1986|4 septembre 1986` },
                    { value: `moment|L`, label: `04/15/1986|15/04/1986` },
                    { value: `moment|dddd`, label: `Week Day|Jour de la semaine` },
                    { value: `moment|MMMM`, label: `Month|Mois` },
                    { value: `moment|DD`, label: `Day|Jour` },
                    { value: `moment|YYYY`, label: `Year|Année` },
                ]
            },
            {
                name: `Time Only|Heure seulement`,
                type: `time`,
                glyph: `fas fa-clock`,
                date: true,
                group: `any-text`
            },
            {
                name: `Email|Courriel`,
                type: `email`,
                glyph: `fas fa-envelope`,
                group: `any-text`
            },
            {
                name: `Phone|Téléphone`,
                type: `tel`,
                glyph: `fas fa-phone`,
                group: `any-text`,
                formats: [
                    { value: `tel|american`, label: `North American|Nord américain` },
                    { value: `raw`, label: `No formatting|Sans format` },
                ]
            },
            {
                name: `Number|Nombre`,
                type: `number`,
                glyph: `fas fa-input-numeric`,
                group: `any-text`
            },
            {
                name: `Keywords|Mots-clés`,
                type: `keywords`,
                glyph: `fas fa-tags`,
                group: `any-text`
            },
            {
                name: `Symbol|Symbole`,
                type: `glyph`,
                glyph: `fas fa-symbols`
            },
            {
                name: `Color|Couleur`,
                type: `color`,
                glyph: `fas fa-palette`
            },
            {
                name: `URL`,
                type: `url`,
                glyph: `fas fa-globe`,
                group: `any-link`
            },
            {
                name: `File|Fichier`,
                type: `file`,
                glyph: `fas fa-file`,
                group: `any-link`
            },
            {
                name: `Image`,
                type: `image`,
                glyph: `fas fa-image`,
                group: `any-link`
            },
            {
                name: `Hours|Horaires`,
                type: `hours`,
                glyph: `fak fa-solid-list-clock`
            }
        ]
    }

    static getField(type) {
        return this.fields.find(f => f.type === type);
    }

    static newField(type = `text`, required = false) {
        return {
            id: type,
            type: type,
            required: required
        }
    }

    static filterTypes(filter) {
        return this.fields.filter(f => f.group === filter || f.type === filter);
    }
}
