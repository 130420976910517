import store from "@/store.js";

export default class CmsLanguageProxy {
    // constructor(owner, name) {
    //     this.owner = owner;
    //     this.name = name;
    //
    //     // this.createProxy();
    //
    //     new Proxy(this, {
    //         get(target, prop, receiver) {
    //             console.log(`get language proxy`, name, prop, owner, owner[prop]);
    //             if (prop && typeof owner[prop] === `object` && owner[prop]) {
    //                 return owner[prop][store.getters.displayLanguage];
    //             } else {
    //                 return Reflect.get(owner, prop, receiver);
    //             }
    //         },
    //         set(target, prop, value) {
    //             console.log(`set language proxy`, name, prop, owner, value, owner[prop]);
    //             if (prop && typeof owner[prop] === `object`) {
    //                 owner[prop][store.getters.displayLanguage] = value;
    //             } else {
    //                 owner[prop] = value;
    //             }
    //             return true;
    //         }
    //     });
    // }

    // Warning, by default Proxy are triggered even on all owner properties. So only apply proxy when fields are part of the "whitelist" languageProxyFields
    static createProxy(owner) {
        return new Proxy(owner, {
            get(target, prop, receiver) {
                if (prop && target.languageProxyFields && target.languageProxyFields.indexOf(prop) >= 0 && typeof target[prop] === `object` && target[prop]) {
                    console.log(`get language proxy`, prop, owner, owner[prop]);
                    return target[prop][store.getters.displayLanguage];
                } else {
                    return Reflect.get(target, prop, receiver);
                }
            },
            set(target, prop, value) {
                if (prop && target.languageProxyFields && target.languageProxyFields.indexOf(prop) >= 0 && typeof target[prop] === `object`) {
                    console.log(`set language proxy`, prop, owner, value, owner[prop]);
                    target[prop][store.getters.displayLanguage] = value;
                } else {
                    target[prop] = value;
                }
                return true;
            }
        });
    }
}
