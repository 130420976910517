import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsBlockTextAndImage from "@/helpers/editor/blocks/CmsBlockTextAndImage.js";

export default class CmsBlockColumn extends CmsBlock {
    static typeDef = {
        name: `Column|Colonne`,
        type: `column`,
        category: `layout`,
        glyph: `fa-bars`,
        isParent: true,
        hidden: true
    }

    get maxChildren() {
        return 20;
    }

    constructor(data, page) {
        super(data, page);

        if (!data) {
            let block = new CmsBlockTextAndImage();
            block.layout = `default`;
            this.addChild(block);
        }
    }
}
