import EditorPadding from "@/helpers/cms/EditorPadding.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import {tr} from "../../../../vue-components/helpers/i18n.js";

export default class CmsBlockText extends CmsBlock {
    static typeDef = {
        name: `Text|Texte`,
        type: `text`,
        category: `base`,
        glyph: `fa-font-case`,
        isParent: false
    }

    get displayName() {
        if (this.text.useBinding && this.text.textField) {
            return `[${this.text.textField.toUpperCase()}]`;
        } else {
            return this.extractHtmlText(tr(this.text.text));
        }
    }

    constructor(data, page) {
        super(data, page);

        this.text = new CmsComponentText(this.text);

        if (!data) {
            this.padding = new EditorPadding({left: 20, top: 10, right: 20, bottom: 10});
            this.text.initText({en: `Text`, fr: `Texte`});
        }
    }

    async translate(from, to) {
        await this.text.translate(from, to);
    }
}
