import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsComponentIframe from "@/helpers/editor/components/CmsComponentIframe.js";

export default class CmsBlockYoutubeVideo extends CmsBlock {
    static typeDef = {
        name: `YouTube Video|Vidéo YouTube`,
        type: `youtube-video`,
        category: `widget`,
        glyph: `fab fa-youtube`,
        isParent: false
    }

    constructor(data, page) {
        super(data, page);

        this.iframe = new CmsComponentIframe(this.iframe);

        if (!data) {
            this.padding.init(0);
            this.valid = false;
            this.videoUrl = ``;
            this.id = false;
            this.muted = true;
            this.autoplay = false;
            this.loop = false;
            this.showClosedCaptions = true;
            this.lang = ``;
            this.showControls = false;
            this.contentType = `video`;
            this.duration = null;
            this.description = null;
            this.thumbnail = null;
            this.videoMetadata = null;
        }
    }

    updateVideoUrl() {
        if (!this.videoMetadata || !this.videoMetadata.iframeUrl) {
            return;
        }
        let url = `${this.videoMetadata.iframeUrl}?autoplay=${this.autoplay ? 1 : 0}&playsinline=1&modestbranding=1&rel=0&fs=1&showinfo=0`;
        if (this.muted) {
            url += `&mute=1`;
        }
        if (this.showClosedCaptions) {
            url += `&cc_load_policy=1`;
            if (this.lang) {
                url += `&cc_lang_pref=${this.lang}`;
            } else {
                url += `&cc_lang_pref=en`;
            }
        }
        if (this.loop) {
            url += `&loop=1`;
        }
        if (this.showControls) {
            url += `&autohide=1`;
        } else if (this.showControls) {
            url += `&autohide=2`;
        }
        this.iframe.languageProxy.src = url;
    }
}
