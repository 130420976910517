import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsBlockCard from "@/helpers/editor/blocks/CmsBlockCard.js";

export default class CmsBlockCards extends CmsBlock {
    static typeDef = {
        name: `Card Grids|Groupe de cartes`,
        glyph: `fa-grid-2`,
        type: `cards`,
        category: `layout`,
        isParent: true
    }

    get childClass() {
        return [CmsBlockCard];
    }

    get maxChildren() {
        return 20;
    }

    constructor(data, page) {
        super(data, page);

        if (!data) {
            this.spacing = `10px`;
            this.columns = 2;
            this.mediaQuery.mobile.columns = 1;

            this.addChild(new CmsBlockCard());
            this.addChild(new CmsBlockCard());
        }
    }
}
