export default class CmsType {
    constructor(data) {
        for (let key in data) {
            this[key] = data[key];
        }
        this.searchableFields = this.fields.filter(f => f.searchable);
        // if (typeof this.name === `object`) {
        //     this.name = tr(this.name);
        // }
    }

    getFieldType(field) {
        if (field === `secondaryField`) {
            field = this.secondaryField;
        } else if (field === `previewImage`) {
            field = this.previewImage;
        }
        return this.fields.find(f => f.id === field);
    }

    getSearchableFields() {
        return this.searchableFields;
    }
}
