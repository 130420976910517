/* eslint-disable */
// Otherwise throws invalid errors on dynamic import

import Vue from 'vue';
import VueRouter from 'vue-router';
import Network from "../vue-components/helpers/Network.js";
import store from "./store.js";

Vue.use(VueRouter);

function initPreview(to, from, next) {
    let websiteId = to.params.websiteId;
    if (websiteId) {
        localStorage.setItem(`websiteId`, websiteId);
        store.dispatch(`loadWebsite`, websiteId)
            .then(() => {
                return next(`/`);
            });
    } else {
        next();
    }
}

function authenticateWithWebsite(to, from, next) {
    ifAuthenticated(to, from, () => {
        let websiteId = to.params.websiteId;
        if (websiteId) {
            localStorage.setItem(`websiteId`, websiteId);
        } else {
            if (to.query.websiteId) {
                websiteId = to.query.websiteId;
                localStorage.setItem(`websiteId`, websiteId);
            } else {
                websiteId = localStorage.getItem(`websiteId`);
            }
        }

        if (store.getters.currentWebsite) {
            return next();
        }
        store.dispatch(`loadWebsite`, websiteId)
            .then(() => {
                next();
                // if (store.getters.currentWebsite) {
                //     next();
                // } else {
                //     next(`/error`);
                // }
            });
    });
}

function authenticateNoWebsite(to, from, next) {
    ifAuthenticated(to, from, () => {
        store.state.applicationReady = true;
        return next();
    });
}

function ifAuthenticated(to, from, next) {
    if (store.getters.isAuthenticated) {
        next();
    } else {
        let token = new URLSearchParams(window.location.search).get(`token`);

        if (token) {
            Network.setToken(token);
        }

        store.dispatch(`getCurrentUser`)
            .then(() => {
                if (store.getters.isAuthenticated) {
                    next();
                } else {
                    const redirectURL = to ? (window.location.href + `#` + to.path) : window.location.href;
                    window.location.href = window.env.VUE_APP_URLS__LOGIN + `/?redirect_url=` + encodeURIComponent(redirectURL);
                }
            });
    }
}

function loadPage(to, from, next) {
    store.state.editMode = false;
    store.dispatch(`loadPage`, to)
        .then(() => {
            if (store.getters.currentPage) {
                next();
            } else {

                next();
                // next(`/error`);
            }
        });
}

const routes = {
    editor: [
        {
            path: `/cms/editor/website/creation/:dealerid`,
            name: `iframe-website-creation`,
            component: () => import('./views/iframe-website-creation.vue'),
            beforeEnter: authenticateNoWebsite
        },
        {
            path: `/cms/editor/website/:websiteId/pages/creation`,
            name: `iframe-page-creation`,
            component: () => import('./views/iframe-page-creation.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/cms/editor/website/:websiteId/settings`,
            name: `iframe-website-settings`,
            component: () => import('./views/iframe-website-settings.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/cms/editor/website/:websiteId/:pageId`,
            name: `web-editor`,
            component: () => import('./views/web-editor.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/cms/preview/website/:websiteId/:pageId`,
            name: `web-preview`,
            component: () => import('./views/web-preview.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/preview/map/:websiteId/:pageId`,
            name: `map-preview`,
            component: () => import('./views/cms-page-map.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/init-preview/:websiteId`,
            name: `init-preview`,
            beforeEnter: initPreview
        },
        {
            path: `/test`,
            redirect(to) {
                return `/init-preview/66466eecb4a254c27842c53a`;
            }
        }
    ],
    client: [
        {
            path: `/error`,
            name: `error`,
            component: () => import('./views/default-error-page.vue')
        },
        {
            path: `*`,
            name: `cms-page-selector`,
            component: () => import('./views/cms-page-selector.vue'),
            beforeEnter: loadPage
        }
    ]
};

const router = new VueRouter({
    mode: `history`,
    routes: [...routes.editor, ...routes.client]
});

export default router;
