import EditorPadding from "@/helpers/cms/EditorPadding.js";
import CmsBaseClass from "@/helpers/cms/CmsBaseClass.js";
import CmsMediaQuery from "@/helpers/editor/blocks/CmsMediaQuery.js";
import CmsComponentBorder from "@/helpers/editor/components/CmsComponentBorder.js";
import CmsComponentShadow from "@/helpers/editor/components/CmsComponentShadow.js";
import {tr} from "../../../../vue-components/helpers/i18n.js";

export default class CmsBlock extends CmsBaseClass {
    // to avoid calling constructor from instance
    get typeDef() {
        return this.constructor.typeDef;
    }

    get displayName() {
        if (this.name) {
            return this.name;
        } else if (this.item) {
            return tr(this.item.name);
        } else {
            return tr(this.typeDef.name).toUpperCase();
        }
    }

    // disabled for fixed sections like headers
    get draggable() {
        return true;
    }

    get layoutPresets() {
        return [];
    }

    /**
     * Only for parents
     * @returns {number}
     */
    get maxChildren() {
        return 4;
    }

    /**
     * Limit the type of children that can be appended to the list below
     * Only for parents
     * @returns {*[]}
     */
    get childClass() {
        return [];
    }

    static typeDef = {
        name: `Block`,
        glyph: `fa-grid-2`,
        type: `block`,
        isParent: false,
        category: `default`,
        hidden: true
    }

    static BACKGROUNDS = {
        NONE: `none`,
        IMAGE: `image`,
        COLOR: `color`
        // GRADIENT: `gradient`
    }

    static CONTAINERS = {
        CONTAINED: `contained`,
        EXTENDED: `extended`,
        FILL_WIDTH: `fill`
    }

    get ignoredFields() {
        return [`page`, `itemTypeDef`, `item`, `mediaProxy`, `languageProxy`];
    }

    constructor(data, page) {
        super(data);

        this.type = this.typeDef.type;
        this.padding = new EditorPadding(this.padding);
        this.borders = new CmsComponentBorder(this.borders);
        this.shadow = new CmsComponentShadow(this.shadow);

        if (this.mediaQuery && this.mediaQuery.expanded === undefined) {
            this.mediaQuery = null;
        }

        // object to hold mobile, kiosk and tablet variants
        this.mediaQuery = new CmsMediaQuery(this.mediaQuery);

        // The Media Proxy will use the component values or the one from mediaQuery with the device and preview
        this.mediaProxy = this.mediaQuery.createProxy(this, `blockMediaProxy`);

        if (!data) {
            this.name = null;
            this.valid = true;
            this.error = null;
            this.layout = `default`;
            this.theme = `default`;
            this.container = CmsBlock.CONTAINERS.CONTAINED;

            this.background = CmsBlock.BACKGROUNDS.NONE;
            this.backgroundColor = `#000`;
            this.backgroundImage = null;
            this.backgroundRadius = `0`;

            this.borders.active = false;
            this.shadow.active = false;

            this.align = `default`;
            this.verticalSpacing = `default`;

            // any css
            this.style = ``;
            this.cssClass = ``;

            this.colorScheme = {};

            // CMS Part
            this.cmsEnabled = false;
            this.itemId = null;
            this.item = null;
            this.itemType = `inherit`;

            // for rows/columns
            this.children = [];
            this.childrenReadonly = false;
        } else {
            this.children = [];

            for (let child of data.children) {
                this.children.push(window.cmsFactory.new(child, page));
            }
        }

        if (page) {
            this.itemTypeDef = page.itemTypeDef;

            if (this.itemId) {
                this.item = page.cms.getItemById(this.itemId);
            } else {
                this.item = null;
            }
        }
    }

    applyChanges(language) {
        for (let key in this) {
            if (this[key] && key !== `mediaProxy` && typeof this[key] === `object`) {
                if (this[key].applyChanges && typeof this[key].applyChanges === `function`) {
                    this[key].applyChanges(language);
                }
            }
        }
    }

    duplicate() {
        let copy = new this.constructor(structuredClone(this.toJSON()));
        copy.applyNewId();
        return copy;
    }

    /**
     * Assign a new ID to the item and recursively to all it's children
     * Use when duplicating item to ensure children also get new ID
     */
    applyNewId() {
        this.id = generateGuid();
        if (this.children) {
            for (let child of this.children) {
                child.applyNewId();
            }
        }
    }

    addChild(childBlock) {
        this.children.push(childBlock);
        this.onChildAdded(childBlock);
        return childBlock;
    }

    checkSelection(selection, blockSelected) {
        if (this.id === blockSelected.id) {
            selection.push(this);
            return true;
        }
        for (let child of this.children) {
            if (child.checkSelection(selection, blockSelected)) {
                selection.push(this);
                return true;
            }
        }
        return false;
    }

    initNewBlock(website) {
        this.colorScheme = website.getColorScheme();
    }

    duplicateChild(child) {
        let copy = child.duplicate();
        this.children.splice(this.children.indexOf(child) + 1, 0, copy);
        this.onChildAdded(copy);
        return copy;
    }

    onChildAdded(child) {
        // to override
    }

    deleteChild(child) {
        this.children.removeItem(child);
    }

    extractHtmlText(html, maxLength = 24) {
        let div = document.createElement(`div`);
        div.innerHTML = html;
        if (div.textContent.length > maxLength) {
            return div.textContent.substring(0, maxLength) + `...`;
        } else {
            return div.textContent;
        }
    }

    async translate(from, to) {
        for (let key in this) {
            if (typeof this[key] === `object` && typeof this[key].translate === `function`) {
                await this[key].translate();
            }
        }
        for (let child of this.children) {
            await child.translate(from, to);
        }
    }

    setValidation(isValid, error = null) {
        this.valid = isValid;
        this.error = error;
    }

    /**
     * To provide refactoring compatibility only
     * @param oldField {string} old key (ex: height))
     * @param newField {string} new key (ex: size)
     */
    renameField(oldField, newField) {
        if (this.hasOwnProperty(oldField) && !this.hasOwnProperty(newField)) {
            this[newField] = this[oldField];
            delete this[oldField];
            const devices = [`mobile`, `tablet`, `kiosk`];
            for (let device of devices) {
                if (this.mediaQuery[device].hasOwnProperty(oldField) && !this.mediaQuery[device].hasOwnProperty(newField)) {
                    this.mediaQuery[device][newField] = this.mediaQuery[device][oldField];
                    delete this.mediaQuery[device][oldField];
                }
            }
        }
    }
}
