import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsBlockImage from "@/helpers/editor/blocks/CmsBlockImage.js";

export default class CmsBlockGallery extends CmsBlock {
    static typeDef = {
        name: `Gallery|Galerie`,
        type: `gallery`,
        category: `widget`,
        glyph: `fa-objects-column`,
        isParent: true
    }

    get maxChildren() {
        return 20;
    }

    get childClass() {
        return [CmsBlockImage];
    }

    constructor(data, page) {
        super(data, page);

        if (!data) {
            this.columnGap = `10px`;
            this.addChild(new CmsBlockImage());
            this.addChild(new CmsBlockImage());
        }
    }

    onChildAdded(child) {
        child.padding.init(0);
    }
}
