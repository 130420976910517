import CMS from "@/helpers/cms/CMS.js";
import CmsPage from "@/helpers/cms/CmsPage.js";
import CmsWebsite from "@/helpers/cms/CmsWebsite.js";
import router from "@/router.js";
import Vue from 'vue';
import swal from "sweetalert";
import Vuex from 'vuex';
import {getLanguage} from "../vue-components/helpers/i18n.js";
import Network from "../vue-components/helpers/Network.js";
import GoogleFontStore from "@/helpers/google-font-store.js";
import MapStore from "@/helpers/maps/map-store.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        applicationLoading: false,
        applicationReady: false,
        dealerid: null,
        page: null,
        pages: {},
        website: null,
        cms: null,
        user: null,
        activeTutorial: null,
        env: localStorage.getItem(`env`) || `dev`,
        editMode: true,
        previewLanguage: null,
        // mobile | tablet | kiosk | desktop
        previewMode: `desktop`,
        displayMode: `desktop`,
        accessibility: {
            wheelchair: JSON.parse(localStorage.getItem(`wheelchair`)) || false
        },
        idleTimeout: false,
        playerId: false,
        isKioskDevice: false,
        isMobileLayout: false,
        isSmallLayout: false,
        isTabletLayout: false,
        isMobileOrTabletLayout: false,
        isLargeLayout: false,
        isExtraLargeLayout: false
    },
    getters: {
        cms(state) {
            return state.cms ? state.cms : null;
        },
        currentPage(state) {
            return state.page ? state.page : null;
        },
        currentWebsite(state) {
            return state.website ? state.website : null;
        },
        currentUser(state) {
            return state.user ? state.user : null;
        },
        isAuthenticated(state) {
            return !!state.user;
        },
        displayLanguage(state) {
            if (state.previewLanguage) {
                return state.previewLanguage;
            } else {
                return getLanguage();
            }
        },
        env(state) {
            return state.env;
        }
    },
    mutations: {
        setAccessibilityWheelchair(state, wheelchair) {
            state.accessibility.wheelchair = wheelchair;
            localStorage.setItem(`wheelchair`, JSON.stringify(wheelchair));
        },
        updateDisplayMode(state) {
            if (state.previewMode !== `desktop`) {
                state.displayMode = state.previewMode;
            } else if (state.isKioskDevice) {
                state.displayMode = `kiosk`;
            } else if (state.isMobileLayout) {
                state.displayMode = `mobile`;
            } else if (state.isTabletLayout) {
                state.displayMode = `tablet`;
            } else {
                state.displayMode = `desktop`;
            }
        },
        setCms(state, cms) {
            state.cms = cms;
        },
        setWebsite(state, website) {
            try {
                if (!website) {
                    return;
                }
                website = new CmsWebsite(website);
                state.website = website;
                state.dealerid = state.website.dealerid;

                state.website.initWebsite();
                state.website.applyStyle();

                state.cms = new CMS(state.website);
                state.cms.initTypes(website.types);
                state.cms.addItems(website.items);

                if (state.website.map) {
                    this.commit(`mapEditor/setMap`, state.website.map);
                    state.website.map = state.mapEditor.map;
                }

                state.cms.updateDependencies();
                state.applicationReady = true;

                // for debug only
                window.cmsWebsite = website;
                window.cms = state.cms;
            } catch (e) {
                console.error(e);
                swal(`Network Error`, `Website is not valid`, `error`);
            }
        },
        setPages(state, pages) {
            try {
                state.website.pages = pages;
            } catch (e) {
                console.error(e);
                swal(`Network Error`, `Coulnd't load pages`, `error`);
            }
        },
        setPage(state, data) {
            try {
                let page = new CmsPage(data.page, state.cms, data.route);
                if (!page.mediaProxy.visible && !state.editMode) {
                    if (page.fallbackLink) {
                        return router.push(page.fallbackLink.to || `/`);
                    }
                }
                state.page = page;
                window.cmsPage = state.page;
            } catch (e) {
                console.error(e);
                swal(`Network Error`, `Page is not valid`, `error`);
            }
        },
        startLogin(state) {
            state.loginStatus = `loading`
        },
        authSuccess(state, user) {
            state.loginStatus = `success`;
            state.user = user
        },
        notConnected(state) {
            state.loginStatus = `not_connected`;
        },
        authError(state) {
            state.loginStatus = `error`
        },
        loggedOut(state) {
            state.loginStatus = `logged_out`;
            state.user = null
        }
    },
    actions: {
        async loadWebsite({state, commit}, id) {
            try {
                if (state.applicationReady) {
                    return;
                }
                let url = `/api/public/website/${window.location.hostname}`;
                if (!id && localStorage.getItem(`websiteId`)) {
                    id = localStorage.getItem(`websiteId`);
                }
                if (id) {
                    url += `/${id}`;
                }

                const response = await Network.get(url)
                commit(`setWebsite`, response.data);
            } catch (e) {
                console.error(e);
                commit(`setWebsite`, null);
                swal(`Network Error`, `Couldn't load website`, `error`);
            }
        },
        async refreshWebsite({state, commit}) {
            try {
                let url = `/api/public/website/${window.location.hostname}/${state.website.id}`;

                const response = await Network.get(url)
                commit(`setWebsite`, response.data);
            } catch (e) {
                console.error(e);
                commit(`setWebsite`, null);
                swal(`Network Error`, `Couldn't reload website`, `error`);
            }
        },
        // async reloadPages({state, commit, dispatch}, websiteId) {
        //     try {
        //         const resp = await Network.get(`/api/pages/website/${websiteId}`);
        //         commit(`setPages`, resp.data);
        //     } catch (e) {
        //         console.error(e);
        //         swal(`Network Error`, `Coulnd't load pages`, `error`);
        //     }
        //     state.applicationLoading = false;
        // },
        async loadPage({state, currentWebsite, commit, dispatch}, route) {
            state.applicationLoading = true;
            try {
                if (!state.applicationReady) {
                    await dispatch(`loadWebsite`);
                }
                let routeParams = CMS.parseRoute(route.fullPath);
                for (let redirect of state.website.routing?.redirects) {
                    if (!redirect.exactMatch && `/${routeParams.permalink}`.indexOf(redirect.from) === 0 || redirect.from === `/${routeParams.permalink}`) {
                        if (redirect.to.indexOf(`https`) === 0) {
                            return window.location.href = redirect.to;
                        } else {
                            return router.push(redirect.to);
                        }
                    }
                }
                if (state.pages[routeParams.permalink]) {
                    commit(`setPage`, {page: state.pages[routeParams.permalink], route});
                } else {
                    const response = await Network.get(`/api/public/pages/${state.website.dealerid}/permalink/${routeParams.permalink}`)
                    if (response.data) {
                        state.pages[routeParams.permalink] = response.data;
                    }
                    commit(`setPage`, {page: response.data, route});
                }
            } catch (e) {
                console.error(e);
                commit(`setPage`, null);
                swal(`Network Error`, `Couldn't load page`, `error`);
            }
            state.applicationLoading = false;
        },
        async getCurrentUser({commit, dispatch}) {
            commit(`startLogin`);
            let token = null;

            const currentURL = new URL(document.URL);
            const currentURLParams = new URLSearchParams(currentURL.search);

            if (currentURLParams.has(`token`)) {
                token = currentURLParams.get(`token`);
            }

            if (!token) {
                token = localStorage.getItem(`token`);
            }

            if (!token) {
                commit(`notConnected`);
                return false;
            }

            await Network.setToken(token);
            try {
                const response = await Network.get(`/users/current-user`, null, {system_api: true})
                commit(`authSuccess`, response.data);
            } catch (e) {
                console.error(e);
                dispatch(`logout`, {forceLogout: true});
            }
        },
        logout({commit}, payload = {}) {
            commit(`loggedOut`);
            localStorage.removeItem(`token`);
            Network.removeToken();

            const redirectURL = new URL(window.env.VUE_APP_URLS__LOGIN);
            redirectURL.searchParams.append(`redirect_url`, window.location.href);
            if (payload.forceLogout) {
                redirectURL.searchParams.append(`logout`, `1`);
            }

            window.location.href = redirectURL.toString();
        }
    },
    modules: {
        googleFonts: GoogleFontStore,
        mapEditor: MapStore
    }
});
