import AbstractModel from "./AbstractModel.js";

export {type} from "./AbstractModel.js";

export default class Model extends AbstractModel {
    constructor() {
        super(...arguments);

        if (this.constructor.mixins) {
            for (const MixinClass of this.constructor.mixins) {
                Object.assign(this, new MixinClass());
            }
        }
    }
}
