import CmsComponentImage from "@/helpers/editor/components/CmsComponentImage.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";

export default class CmsBlockImage extends CmsBlock {
    static typeDef = {
        name: `Image|Image`,
        type: `image`,
        category: `base`,
        glyph: `fa-image`,
        isParent: false
    }

    constructor(data, page) {
        super(data, page);

        this.image = new CmsComponentImage(this.image);
        this.image.required = true;

        this.legend = new CmsComponentText(this.legend);

        if (!data) {
            this.legendPosition = `bottom`;
            this.legendTheme = `light`;
            this.legendPadding = `10px`;
            this.legend.active = false;

            this.legend.initText({en: `Legend`, fr: `Légende` });
        }
    }

    async translate(from, to) {
        await this.legend.translate(from, to);
    }
}
