import {translate} from "./i18n";

const siteName = `Eye-In Media`;
export default class SeoHelper {
    static getMeta(title, description, keywords, author, imageUrl, noIndex = false) {
        if (!title) {
            console.warn(`SEO Warning: META Title missing in the page`);
        } else {
            translate(title);
        }
        if (Array.isArray(keywords)) {
            keywords = keywords.join();
        }
        let meta = {
            title: title,
            meta: [
                // Standard META Tags
                { vmid: `author`, name: `author`, content: author },
                // Facebook META Tags (Open Graph)
                { vmid: `og:title`, name: `og:title`, content: title },
                { vmid: `og:site_name`, name: `og:site_name`, content: siteName },
                // Twitter META Tags
                { vmid: `twitter:title`, name: `twitter:title`, content: title }
            ]
        };
        if (description) {
            meta.meta.push({ vmid: `description`, name: `description`, content: description });
            meta.meta.push({ vmid: `og:description`, name: `og:description`, content: description });
            meta.meta.push({ vmid: `twitter:description`, name: `twitter:description`, content: description });
        }
        if (keywords) {
            meta.meta.push({ vmid: `keywords`, name: `keywords`, content: keywords });
        }
        if (imageUrl) {
            meta.meta.push({ vmid: `og:image`, name: `og:image`, content: imageUrl });
            meta.meta.push({ vmid: `twitter:image`, name: `twitter:image`, content: imageUrl });
            meta.meta.push({ vmid: `twitter:card`, name: `twitter:card`, content: `summary_large_image` });
        }
        if (noIndex) {
            meta.meta.push({ vmid: `robots`, name: `robots`, content: `noindex,nofollow` });
        }
        return meta;
    }
}
