import CmsBaseClass from "@/helpers/cms/CmsBaseClass.js";
import CmsLink from "@/helpers/cms/CmsLink.js";
import StyleHelper from "@/helpers/StyleHelper.js";
import store from "@/store.js";

export default class CmsWebsite extends CmsBaseClass {
    static TYPES = {
        WEBSITE: `website`,
        KIOSK: `kiosk`
    }

    dealerid;
    type = CmsWebsite.TYPES.WEBSITE;
    name = {
        en: `New Website`
    };
    domain;
    alias;
    visibility = `private`;
    settings;
    legal;
    meta;
    design;
    accessibility;
    body;
    menu;
    pages;
    footer;
    integrations;
    map;
    style;

    get autoGenerateId() {
        return false;
    }

    get ignoredFields() {
        return [`styleVariables`, `elements`, `data`, `items`, `types`, `map`];
    }

    constructor(data) {
        super();

        this.elements = {};

        this.loadSave(data);

        if (!store.state.editMode && !window.location.pathname.startsWith(`/cms/`)) {
            this.injectScripts();
        }

        if (!this.accessibility) {
            this.accessibility = {
                wheelchair: {
                    topMargin: `880px`,
                    background: `#3D454A`,
                    placeholder: {
                        "src": ``
                    }
                }
            }
        }
        if (!this.aliases) {
            this.aliases = [
                {domain: this.domain, env: `LIVE`}
            ]
        }
        if (!this.routing) {
            this.routing = {
                redirects: []
            }
        }
    }

    initWebsite() {
        // Update Navigation Links
        let links = [];

        for (let link of this.menu.links) {
            links.push(new CmsLink(link));
        }
        this.menu.links = links;

        if (this.menu.block) {
            this.menu.block = window.cmsFactory.new(this.menu.block);
        }
        if (this.footer.block) {
            this.footer.block = window.cmsFactory.new(this.footer.block);
        }
    }

    toJSON() {
        let data = super.toJSON();
        data.pages = data.pages.map(p => p.id);
        return data;
    }

    getColor(colorOrVariant) {
        if (!this.design.colors.hasOwnProperty(colorOrVariant)) {
            return colorOrVariant;
        }
        return this.design.colors[colorOrVariant];
    }

    getMapPage() {
        return this.pages.find(p => p.type === `map`);
    }

    injectScripts() {
        if (this.settings.analytics.googleTags) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function() {
                dataLayer.push(arguments);
            }
            gtag(`js`, new Date());
            gtag(`config`, this.settings.analytics.googleTags);
        }

        if (this.settings.analytics.metaPixel && fbq) {
            fbq(`init`, this.settings.analytics.metaPixel);
            fbq(`track`, `PageView`);
        }
    }

    applyStyle() {
        this.styleVariables = {};
        this.elements.favicon = document.getElementById(`favicon`);
        if (this.elements.favicon) {
            this.elements.favicon.href = this.design.favicon.src;
        }

        if (!this.design.fonts.headlines) {
            this.design.fonts.headlines = this.design.fonts.titles;
        }
        if (!this.design.fonts.buttons) {
            this.design.fonts.buttons = this.design.fonts.body;
        }

        for (let type of this.fontTypes) {
            const fontStyle = this.design.fonts[type];
            if (fontStyle.font && store.getters[`googleFonts/loadedFonts`].indexOf(fontStyle.fontFamily) < 0) {
                let variant = ``;
                if (fontStyle.font.weightRange?.start) {
                    variant = `:wght@${fontStyle.font.weightRange.start}..${fontStyle.font.weightRange.end}`;
                }
                let link = document.createElement(`link`);
                link.setAttribute(`rel`, `stylesheet`);
                link.setAttribute(`type`, `text/css`);
                link.setAttribute(`href`, `https://fonts.googleapis.com/css2?family=${fontStyle.fontFamily}${variant}&display=swap`);
                document.head.appendChild(link);
                store.commit(`googleFonts/setLoaded`, fontStyle.fontFamily);
            }
        }

        if (this.style.customCSS) {
            let link = document.createElement(`style`);
            link.innerHTML = this.style.customCSS;
            document.body.appendChild(link);
        }

        /*
        CSS Custom Variables
         */
        // Fonts
        this.setFontStyle(`headlines`, this.design.fonts.headlines);
        this.setFontStyle(`titles`, this.design.fonts.titles);
        this.setFontStyle(`body`, this.design.fonts.body);
        this.setFontStyle(`buttons`, this.design.fonts.buttons);

        // Body
        this.setCssProperty(`--page-width`, `${StyleHelper.getResponsiveStyleProp(this.body.design.style, `maxWidth`)}px`);
        this.setCssProperty(`--body-color`, StyleHelper.getResponsiveStyleProp(this.body.design.style, `color`));
        this.setCssProperty(`--body-background`, StyleHelper.getResponsiveStyleProp(this.body.design.style, `background`));

        // Fonts
        this.setCssProperty(`--font-base-size`, this.design.fonts.baseSize);
        this.setCssProperty(`--font-scale`, this.design.fonts.scale);

        // Color Scheme
        for (let variant in this.design.colors) {
            this.setCssProperty(`--${variant}-color`, this.design.colors[variant]);
        }

        this.setCssProperty(`--border-radius`, this.design.borders.radius);
        this.setCssProperty(`--border-width`, this.design.borders.width);
        this.setCssProperty(`--border-color`, this.design.borders.color);

        // Menu
        if (this.menu.block) {
            if (this.menu.block.mediaProxy.orientation === `horizontal`) {
                this.setCssProperty(`--menu-width`, `0px`);
                this.setCssProperty(`--menu-height`, `${this.menu.block.mediaProxy.size}px`);
                if (this.menu.block.mediaProxy.anchor === `top`) {
                    this.setCssProperty(`--page-padding-top`, `${this.menu.block.mediaProxy.size}px`);
                } else {
                    this.setCssProperty(`--page-padding-bottom`, `${this.menu.block.mediaProxy.size}px`);
                }
            } else {
                this.setCssProperty(`--menu-width`, `${this.menu.block.mediaProxy.size}px`);
                this.setCssProperty(`--menu-height`, `0px`);
                if (this.menu.block.mediaProxy.anchor === `left`) {
                    this.setCssProperty(`--page-padding-left`, `${this.menu.block.mediaProxy.size}px`);
                } else {
                    this.setCssProperty(`--page-padding-right`, `${this.menu.block.mediaProxy.size}px`);
                }
            }
        }

        // Cards
        this.setCssProperty(`--cards-box-shadow`, StyleHelper.getResponsiveStyleProp(this.design.cards.style, `boxShadow`));
        this.setCssProperty(`--cards-border-radius`, StyleHelper.getResponsiveStyleProp(this.design.cards.style, `borderRadius`));
    }

    get fontTypes() {
        return [`titles`, `body`, `headlines`, `buttons`];
    }

    setFontStyle(name, value) {
        if (value) {
            this.setCssProperty(`--${name}-font`, value.fontFamily);
            this.setCssProperty(`--${name}-font-weight`, value.fontWeight);
            this.setCssProperty(`--${name}-letter-spacing`, value.letterSpacing);
        }
    }

    makeI18nText(inTexts) {
        let texts = {};
        for (let lang of this.settings.languages) {
            if (inTexts[lang]) {
                texts[lang] = inTexts[lang];
            } else {
                texts[lang] = lang.toUpperCase();
                console.warn(`Missing lang ${lang} in makeI18nText`);
            }
        }
        return texts;
    }

    getColorSchemeVariables(colorScheme) {
        let style = {};

        if (colorScheme) {
            style[`--scheme-bg`] = this.getColor(colorScheme.background);
            style[`--scheme-title-color`] = this.getColor(colorScheme.title);
            style[`--scheme-paragraph-color`] = this.getColor(colorScheme.paragraph);
            style[`--scheme-button-bg-color`] = this.getColor(colorScheme.buttonBG);
            style[`--scheme-button-color`] = this.getColor(colorScheme.buttonColor);
            style[`--scheme-button-outline-bg-color`] = this.getColor(colorScheme.outlineBG);
            style[`--scheme-button-outline-color`] = this.getColor(colorScheme.outlineColor);
            style[`--scheme-button-link-color`] = this.getColor(colorScheme.linkColor);
        }
        return style;
    }

    getColorScheme(themeId = `default`) {
        return cmsWebsite.design.colorSchemes.find(c => c.id === themeId);
    }

    setCssProperty(name, value) {
        if (value && value !== ``) {
            this.styleVariables[name] = value;
        }
    }
}
