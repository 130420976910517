import CmsComponent from "@/helpers/editor/components/CmsComponent.js";

export default class CmsComponentBorder extends CmsComponent {
    type = `border`

    get allowMediaQuery() {
        return true;
    }

    constructor(data) {
        super(data);

        if (!data) {
            this.color = `#cccccc`;
            this.width = `none`;
            this.style = `solid`;
        }
    }
}
