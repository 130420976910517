import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";

export default class CmsBlockDivider extends CmsBlock {
    static typeDef = {
        name: `Divider|Séparateur`,
        type: `divider`,
        category: `base`,
        glyph: `fa-horizontal-rule`,
        isParent: false
    }

    constructor(data, page) {
        super(data, page);

        if (!data) {
            this.height = `8px`;
            this.borderRadius = `4px`;
            this.color = `#777`;
            this.width = `100%`;
        }
    }
}
