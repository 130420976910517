import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsBlockListItem from "@/helpers/editor/blocks/CmsBlockListItem.js";
import CmsComponentBorder from "@/helpers/editor/components/CmsComponentBorder.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";

export default class CmsBlockBlock extends CmsBlock {
    static typeDef = {
        name: `Search|Recherche`,
        type: `search`,
        category: `widget`,
        glyph: `fa-magnifying-glass`,
        isParent: true,
        cmsItem: true
    }

    get layoutPresets() {
        return [
            {
                id: `default`,
                name: `Default|Défaut`
            },
            {
                id: `cards`,
                name: `Cards|Cartes`
            }
        ]
    }

    constructor(data, page) {
        super(data, page);

        this.childrenReadonly = true;

        this.title = new CmsComponentText(this.title);
        this.subtitle = new CmsComponentText(this.subtitle);
        this.divider = new CmsComponentBorder(this.divider);

        if (!data) {
            this.title.active = false;
            this.subtitle.active = false;
            this.divider.width = `2px`;

            this.itemType = `inherit`;
            this.placeholder = {
                en: `Search...`,
                fr: `Rechercher...`
            };

            this.autofocus = true;

            this.spacing = `10px`;
            this.fillWidth = false;
            this.maxHeight = `none`;
            this.maxSearchResult = `10`;
            this.displayKeywords = true;
            this.displayOnSearchOnly = true;
            this.columns = 1;
            this.mediaQuery.mobile.columns = 1;
            this.size = `md`;
            this.borderRadius = `var(--border-radius)`;

            let listItem = new CmsBlockListItem();
            listItem.name = `Search Result Item|Résultat de recherche`;
            listItem.layout = `row-left`;
            listItem.image.active = false;
            listItem.title.itemField = `name`;
            listItem.subtitle.itemField = `secondaryField`;
            listItem.subtitle.active = false;
            listItem.description.itemField = `description`;
            listItem.description.active = false;
            this.addChild(listItem);
        }
        if (this.displayKeywords === undefined) {
            this.displayKeywords = true;
        }
    }
}
