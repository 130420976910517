import CmsLink from "@/helpers/cms/CmsLink.js";
import CmsComponent from "@/helpers/editor/components/CmsComponent.js";
import store from "@/store.js";

export default class CmsComponentButton extends CmsComponent {
    get allowBinding() {
        return true;
    }

    get allowMediaQuery() {
        return true;
    }

    type = `button`

    constructor(data) {
        super(data);

        this.link = new CmsLink(this.link);

        if (!data) {
            this.size = `md`;
            this.buttonType = `solid`;
            this.borderRadius = `var(--border-radius)`;

            this.leftIcon = null;
            this.rightIcon = null;

            this.text = {};

            // Item Binding
            this.textField = null;
            this.linkField = null;
        }

        this.newText = this.text;
    }

    // do not init in constructor because cmsWebsite is not ready yet
    initText(text = { en: `Button`, fr: `Bouton` }, preset = `default`) {
        this.text = store.state.website.makeI18nText(text);
        this.newText = text;
        this.preset = preset;
    }

    getText(lang) {
        return this.text[lang];
    }

    applyChanges(language) {
        this.text = this.newText;
    }
}
