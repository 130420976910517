import FieldsHelper from "@/helpers/cms/FieldsHelper.js";
import CmsItem from "./CmsItem.js";
import CmsType from "./CmsType.js";

export default class CMS {
    constructor(website) {
        this.website = website;
        this.items = [];
        this.types = [];
    }

    initTypes(types) {
        this.types = this.types.concat(types.map(type => new CmsType(type)));
    }

    addItems(items) {
        this.items = this.items.concat(items.map(item => new CmsItem(item, this.getTypeById(item.type))));
    }

    updateDependencies() {
        for (let type of this.types) {
            let items = this.getItemsByType(type.type);
            for (let item of items) {
                if (this.website.map) {
                    const mapItems = this.website.map.getItemsByCmsItemId(item.id);
                    if (mapItems) {
                        for (let mapItem of mapItems) {
                            item.addMapItem(mapItem);
                        }
                    } else {
                        // console.warn(`item ${item.name.fr} is missing map item`)
                    }
                }

                for (let key in type.linkedItems) {
                    if (item[type.linkedItems[key]]) {
                        item[key] = this.getItemById(item[type.linkedItems[key]]);
                    } else {
                        item[key] = null;
                    }
                }
            }
        }
    }

    getTypeById(typeId) {
        if (typeId === `universal-search`) {
            return this.getUniversalSearchType();
        }
        return this.types.find(type => type.type === typeId);
    }

    getItemById(id) {
        return this.items.find(i => i.id === id);
    }

    getItemByPermalink(id) {
        return this.items.find(i => i.matchPermalink(id));
    }

    getItemsByType(type) {
        if (type === `universal-search`) {
            return this.items.filter(i => i.typeDef.searchBehavior !== `hidden` && i.searchBehavior !== `hidden`);
        }
        return this.items.filter(i => i.type === type);
    }

    getUniversalSearchType() {
        return new CmsType({
            name: `Search|Recherche`,
            fields: [
                {id: `name`, name: `Name|Nom`, type: `text`},
                {id: `subtitle`, type: `text`, hidden: true},
                {id: `image`, type: `image`}
            ],
            imageField: `image`,
            secondaryField: `secondaryField`
        })
    }

    getUniversalSearchPreviewItem() {
        return {
            name: `Result 1|Résultat 1`,
            description: `Search result description|Description du résultat de recherche`,
            secondaryField: `Category|Catégorie`,
            image: `https://eyeinlivestorage.blob.core.windows.net/public/icons/eye-in-media.png`
        };
    }

    getFirstItemByType(type) {
        let items = this.items.filter(i => i.type === type);
        return items.length > 0 ? items[0] : null;
    }

    getFieldDef(itemType, fieldName) {
        if (fieldName.indexOf(`.`) > 0) {
            // Field from SubObject found xxx.floor.name  root.type.field
            const path = fieldName.split(`.`);
            itemType = this.getTypeById(path[path.length - 2]);
            if (!itemType) {
                return;
            }
            fieldName = path[path.length - 1];
        }

        const field = clone(itemType.getFieldType(fieldName));
        if (!field) {
            return;
        }
        const fieldDef = FieldsHelper.getField(field.type);
        if (!fieldDef) {
            return field;
        }
        field.glyph = fieldDef.glyph;
        field.formats = fieldDef.formats;
        return field;
    }

    getRouteId(fullPath) {
        let routeParams = CMS.parseRoute(fullPath);
        return routeParams.id;
    }

    static parseRoute(fullPath) {
        if (fullPath === `/`) {
            return {
                permalink: ``,
                id: null
            };
        }
        let permalink = ``;
        let id = null;
        fullPath = fullPath.substr(1);// remove first /
        if (fullPath !== ``) {
            const params = fullPath.split(`/`);
            if (params.length > 1) {
                id = params[params.length - 1];
                permalink = fullPath.replace(`/${id}`, ``);
            } else {
                permalink = fullPath;
            }
        }

        return {
            permalink: permalink.toLowerCase(),
            id: id
        }
    }
}
