import store from '../../src/store'
import DealerHelper from "./DealerHelper";

export default class PermissionHelper {
    static get ROLE_TYPES() {
        return [
            { permissionCode: `  `, id: `customers`, name: `Customers|Clients`, icon: `fa-users`, description: `` },
            { permissionCode: `ge`, id: `general`, name: `General|Général`, icon: `fa-shield-alt`, description: `` },
            { permissionCode: `wf`, id: `wifi-beta`, name: `WiFi (2.0)`, icon: `fa-wifi-exclamation`, description: `` },
            { permissionCode: `  `, id: `wifi-legacy`, name: `WiFi (Legacy)`, icon: `fa-wifi`, description: `` },
            { permissionCode: `ds`, id: `digital-signage`, name: `Digital Signage|Affichage numérique`, icon: `fa-tv`, description: `` },
            { permissionCode: `ly`, id: `loyalty`, name: `Loyalty|Loyauté`, icon: `fa-crown`, description: `` },
            { permissionCode: `mp`, id: `maps`, name: `Maps|Plans`, icon: `fa-map`, description: `` },
            { permissionCode: `cm`, id: `cms`, name: `CMS & Website|CMS & Site internet`, icon: `fa-globe`, description: `` },
            { permissionCode: `nw`, id: `communication`, name: `Communication (Emails)|Communication (Emails)`, icon: `fa-envelope`, description: `` },
            { permissionCode: `cs`, id: `chat`, name: `Chat|Chat`, icon: `fa-messages`, description: `` },
            { permissionCode: `fo`, id: `food-force`, name: `Food-Force`, icon: `fa-hat-chef`, description: `` },
            { permissionCode: `  `, id: `employees`, name: `Eye-In Employees|Employés Eye-In`, icon: `fa-user-tie`, description: `` }
        ];
    }

    static getRoleType(typeId) {
        const roleType = this.ROLE_TYPES.find(t => t.id === typeId);
        if (roleType) {
            return roleType;
        } else {
            return {};
        }
    }

    static getAccessLevelVariant(value) {
        switch (value) {
            case `admin`:
                return `warning`;
            case `write`:
                return `primary`;
            case `read`:
                return `success`;
            case `no`:
                return `danger`;
            default:
                return `gray`;
        }
    }

    static can(accessLevel, permission, requiredDealerid) {
        const token = localStorage.getItem(`token`);

        if (!token)
            return false;

        const payload = JSON.parse(atob(token.split(`.`)[1]));

        let readDealers, writeDealers, adminDealers;
        if (permission === undefined) {
            throw new Error(`Error permission is undefined`);
        } else if (permission === null) {
            [readDealers, writeDealers, adminDealers] = this.generateDealerLists(payload);
        } else {
            [readDealers, writeDealers, adminDealers] = this.generateDealerListsForPermission(payload, permission);
        }

        if (!requiredDealerid) {
            requiredDealerid = store.state.dealerid.toLowerCase();
        } else {
            requiredDealerid = requiredDealerid.toLowerCase();
        }

        let dealers = [];
        switch (accessLevel) {
            case `admin`:
                dealers = adminDealers;
                break;
            case `write`:
                dealers = writeDealers;
                break;
            case `read`:
            default:
                dealers = readDealers;
                break;
        }

        if (!requiredDealerid) {
            if (dealers.length > 0)
                return true;
            else
                return false;
        } else {
            if (dealers === `all` || dealers.indexOf(requiredDealerid) >= 0)
                return true;
            else
                return false;
        }
    }

    static generateDealerLists(payload) {
        let readDealers = [];
        let writeDealers = [];
        let adminDealers = [];
        for (let dealerString of payload.dlr) {
            let [dealerAccessLevel, dealerid] = dealerString.split(`/`);
            dealerid = dealerid.toLowerCase();

            // Dealers can have admin / write / read access
            if (dealerAccessLevel === `a`) {
                readDealers.push(dealerid);
                writeDealers.push(dealerid);
                adminDealers.push(dealerid);
                continue;
            }

            if (dealerAccessLevel === `w`) {
                readDealers.push(dealerid);
                writeDealers.push(dealerid);
                continue;
            }

            if (dealerAccessLevel === `r`) {
                readDealers.push(dealerid);
            }
        }

        for (let dealerid of readDealers) {
            if (dealerid === `eye-in wireless`) {
                readDealers = `all`;
                break;
            }
            readDealers = readDealers.concat(DealerHelper.getDealerChildren(dealerid));
        }

        for (let dealerid of writeDealers) {
            if (dealerid === `eye-in wireless`) {
                writeDealers = `all`;
                break;
            }
            writeDealers = writeDealers.concat(DealerHelper.getDealerChildren(dealerid));
        }

        for (let dealerid of adminDealers) {
            if (dealerid === `eye-in wireless`) {
                adminDealers = `all`;
                break;
            }
            adminDealers = adminDealers.concat(DealerHelper.getDealerChildren(dealerid));
        }

        return [readDealers, writeDealers, adminDealers];
    }

    static generateDealerListsForPermission(payload, permission) {
        const [requiredModule, requiredFeature] = permission.code.split(`/`);

        let readDealers = [];
        let writeDealers = [];
        let adminDealers = [];
        for (let dealerString of payload.dlr) {
            let [dealerAccessLevel, dealerid, modules] = dealerString.split(`/`);
            dealerid = dealerid.toLowerCase();

            // if dealer is admin he has all permissions
            if (dealerAccessLevel === `a` && dealerid === `eye-in wireless`) {
                readDealers.push(dealerid);
                writeDealers.push(dealerid);
                adminDealers.push(dealerid);

                continue;
            }

            // if this dealer does not have the required module, skip
            if (modules !== `*` && modules.split(`,`).indexOf(requiredModule) < 0) {
                continue;
            }

            for (let role of payload.rol) {
                const [featureAccessLevel, module, feature] = role.split(`/`);

                if (module !== `*` && module !== requiredModule)
                    continue;

                if (!requiredFeature || feature === `*` || feature === requiredFeature) {
                    // Read only
                    readDealers.push(dealerid);

                    if (featureAccessLevel === `w` || featureAccessLevel === `a`) {
                        if (dealerAccessLevel === `w` || dealerAccessLevel === `a`) {
                            // Write / Read
                            writeDealers.push(dealerid);
                        }
                    }

                    if (featureAccessLevel === `a`) {
                        if (dealerAccessLevel === `a`) {
                            // Full includes Delete / Write / Read
                            adminDealers.push(dealerid);
                        }
                    }
                }
            }
        }

        readDealers = Array.from(new Set(readDealers));
        writeDealers = Array.from(new Set(writeDealers));
        adminDealers = Array.from(new Set(adminDealers));

        for (let dealerid of readDealers) {
            if (dealerid === `eye-in wireless`) {
                readDealers = `all`;
                break;
            }
            readDealers = readDealers.concat(DealerHelper.getDealerChildren(dealerid));
        }

        for (let dealerid of writeDealers) {
            if (dealerid === `eye-in wireless`) {
                writeDealers = `all`;
                break;
            }
            writeDealers = writeDealers.concat(DealerHelper.getDealerChildren(dealerid));
        }

        for (let dealerid of adminDealers) {
            if (dealerid === `eye-in wireless`) {
                adminDealers = `all`;
                break;
            }
            adminDealers = adminDealers.concat(DealerHelper.getDealerChildren(dealerid));
        }

        return [readDealers, writeDealers, adminDealers];
    }
}
