import CmsLink from "@/helpers/cms/CmsLink.js";
import CmsComponent from "@/helpers/editor/components/CmsComponent.js";

export default class CmsComponentLink extends CmsComponent {
    get allowBinding() {
        return true;
    }

    get allowMediaQuery() {
        return true;
    }

    type = `link`

    constructor(data) {
        super(data);

        this.link = new CmsLink(this.link);

        if (!data) {
            // Item Binding
            this.textField = null;
            this.linkField = null;
        }
    }
}
