import CmsBaseClass from "@/helpers/cms/CmsBaseClass.js";
import {getLanguage, tr} from "../../../vue-components/helpers/i18n.js";
import store from "@/store.js"

export default class CmsItem extends CmsBaseClass {
    get ignoredFields() {
        return [`mapItems`];
    }

    /**
     *
     * @param data {Object}
     * @param typeDef {CmsType}
     */
    constructor(data, typeDef) {
        super(data);

        /**
         *
         * @type {CmsType}
         */
        this.typeDef = typeDef;
        if (!this.typeDef) {
            console.error(`Invalid item type for ${this.name}`);
        } else {
            if (typeDef.displayId) {
                this.name = `${this.id} - ${this.name}`;
            }
        }
    }

    matchSearch(searchRegexp) {
        for (let field of this.typeDef.getSearchableFields()) {
            if (!this[field.id])
                continue;

            let value = this[field.id];
            if (field.multilingual) {
                if (searchRegexp.test(this.normalizeValue(value[getLanguage()]))) {
                    return true;
                }
            } else if (typeof value === `object`) {
                for (let key in value) {
                    if (searchRegexp.test(this.normalizeValue(value[key]))) {
                        return true;
                    }
                }
            } else {
                if (searchRegexp.test(this.normalizeValue(value))) {
                    return true;
                }
            }
        }
        return false;
    }

    getMatchingKeywords(searchRegexp) {
        let keywords = [];
        for (let field of this.typeDef.getSearchableFields()) {
            if (!this[field.id])
                continue;

            let value = this[field.id];
            if (field.multilingual) {
                let matches = this.extractMatchingKeywords(searchRegexp, value[getLanguage()]);
                if (matches) {
                    keywords = keywords.concat(matches);
                }
            } else if (typeof value === `object`) {
                for (let key in value) {
                    let matches = this.extractMatchingKeywords(searchRegexp, value[key]);
                    if (matches) {
                        keywords = keywords.concat(matches);
                    }
                }
            } else {
                let matches = this.extractMatchingKeywords(searchRegexp, value);
                if (matches) {
                    keywords = keywords.concat(matches);
                }
            }

        }
        return keywords.join(`, `);
    }

    extractMatchingKeywords(regexp, text) {
        if (!text) {
            return null;
        }
        const words = text.split(`,`);
        let match = [];
        for (let word of words) {
            if (regexp.test(this.normalizeValue(word))) {
                match.push(word);
            }
        }
        return match;
    }

    normalizeValue(value) {
        if (!value) {
            return ``;
        }
        return value.toString().normalize(`NFKD`).replace(/\W/g, ``);
    }

    matchPermalink(permalink) {
        if (this.permalink && typeof this.permalink === `string` && permalink === this.permalink) {
            return true;
        } else if (this.permalink && typeof this.permalink === `object` &&
            (permalink === this.permalink.en || permalink === this.permalink.default || permalink === this.permalink.fr)) {
            return true;
        }
        return permalink === this.id;
    }

    getName() {
        return this.name;
    }

    getField(field) {
        if (field === `secondaryField`) {
            return this.getSecondaryValue();
        } else if (field === `previewImage`) {
            return this.getPreviewImage();
        } else if (field === `self-link`) {
            return this.getViewLink();
        } else if (field === `map-link`) {
            return this.getMapLink();
        } else {
            return this.getNestedValue(field);
        }
    }

    getFieldFormatted(field) {
        let value = this.getField(field);
        if (!value) {
            return value;
        }

        if (typeof value === `object`) {
            return tr(value);
        } else {
            return value;
        }
    }

    addMapItem(mapItem) {
        if (!mapItem)
            return;

        if (!this.mapItems) {
            this.mapItems = [mapItem];
        } else if (this.mapItems.indexOf(mapItem) === -1) {
            this.mapItems.push(mapItem);
        }
        mapItem.setCmsItem(this);
    }

    hasMultipleMapItems() {
        return this.mapItems?.length > 0;
    }

    get mapItem() {
        if (this.mapItems && this.mapItems.length > 0) {
            return this.mapItems[0];
        } else {
            return null;
        }
    }

    getViewLink() {
        if (!this.typeDef.viewUrl) {
            return null;
        }
        let permalink = tr(this.permalink);
        if (!permalink) {
            permalink = this.id;
        }
        return this.typeDef.viewUrl.replace(`{id}`, permalink);
    }

    getMapLink() {
        if (!this.mapItem) {
            return null;
        }

        const mapPage = store.state.website.getMapPage();
        if (mapPage) {
            return `/${tr(mapPage.permalink)}?${tr(`end|arrivee`)}=${this.mapItem.getPermalink()}`;
        } else {
            console.error(`Error, no map page detected`);
            return null;
        }
    }

    getPreviewImage() {
        return this.getNestedValue(this.typeDef.previewImage);
    }

    getSecondaryValue() {
        return this.getNestedValue(this.typeDef.secondaryField);
    }
}
