import CmsComponent from "@/helpers/editor/components/CmsComponent.js";

export default class CmsComponentIframe extends CmsComponent {

    get allowBinding() {
        return true;
    }

    get allowMediaQuery() {
        return true;
    }

    get languageProxyFields() {
        return [`src`];
    }

    type = `iframe`

    constructor(data) {
        super(data);

        if (!data) {
            this.src = ``;
            this.interactive = true;
            this.borderRadius = `var(--border-radius)`;
            this.aspectRatio = `16 / 9`;
            this.height = ``;
        }
    }
}
