import CmsBlockText from "@/helpers/editor/blocks/CmsBlockText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";

export default class CmsBlockInline extends CmsBlock {
    static typeDef = {
        name: `Inline Group|Ligne de contenu`,
        type: `inline`,
        category: `layout`,
        glyph: `fa-distribute-spacing-vertical`,
        isParent: true
    }

    get maxChildren() {
        return 20;
    }

    constructor(data, page) {
        super(data, page);

        if (!data) {
            this.spacing = `10px`;

            this.addChild(new CmsBlockText());
            this.addChild(new CmsBlockText());
        }
    }
}
