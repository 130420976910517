import Vue from 'vue';
import { translate } from './i18n.js';

Vue.directive(`tr`, {
    bind(el, binding) {
        const value = binding.value ? binding.value : el.innerHTML;

        const translation = translate(value);
        if (binding.value && binding.arg) {
            el.setAttribute(binding.arg, translation);
        } else {
            el.innerHTML = translation;
        }
    },
    update: function(el, binding, vnode) {
        let value = null;
        if (vnode.children) {
            value = vnode.children[0].text;
        }
        if (!value && vnode.componentOptions) {
            value = vnode.componentOptions.children[0].text;
        }

        const translation = translate(value);
        if (binding.value && binding.arg) {
            el.setAttribute(binding.arg, translation);
        } else {
            el.innerHTML = translation;
        }
    }
});

Vue.directive(`bg-image`, function(el, binding) {
    el.style.backgroundImage = `url('${binding.value}')`;
    if (binding.modifiers.cover === true) {
        el.style.backgroundSize = `cover`;
    } else if (binding.modifiers.contain === true) {
        el.style.backgroundSize = `contain`;
    }
    if (binding.modifiers.center === true) {
        el.style.backgroundPosition = `center`;
    } else {
        if (binding.modifiers.top === true) {
            el.style.backgroundPositionY = `top`;
        } else if (binding.modifiers.bottom === true) {
            el.style.backgroundPositionY = `bottom`;
        }
        if (binding.modifiers.left === true) {
            el.style.backgroundPositionX = `left`;
        } else if (binding.modifiers.right === true) {
            el.style.backgroundPositionX = `right`;
        }
    }
});

Vue.directive(`aspect`, {
    inserted: function(el, binding) {
        const { aspect, compute } = binding.value;
        if (compute === `width`) {
            el.style.width = el.clientHeight * aspect + `px`;
        } else if (compute === `height`) {
            el.style.height = el.clientWidth / aspect + `px`;
        }
    }
});

Vue.directive(`scroll-bottom`, {
    inserted: function(el) {
        setTimeout(() => {
            el.scrollTop = el.scrollHeight;
        });
    },
    update: function(el) {
        setTimeout(() => {
            el.scrollTop = el.scrollHeight;
        });
    }
});

export default {};
