export default class FileLibraryHelper {
    /**
     * Return an url that include a version number for cache management
     * @param value {string|object}
     * @returns {string|*}
     */
    static getCacheUrl(value) {
        if (typeof value === `object` && value.url) {
            if (value.revision > 0) {
                if (value.url.indexOf(`?`) > 0) {
                    value.url += `&rv=${value.revision}`;
                } else {
                    value.url += `?rv=${value.revision}`;
                }
            }
            return value.url;
        } else {
            return value;
        }
    }
}
