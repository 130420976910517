import CmsComponent from "@/helpers/editor/components/CmsComponent.js";
import store from "@/store.js";

export default class CmsComponentText extends CmsComponent {

    get allowBinding() {
        return true;
    }
    get allowAdvanced() {
        return true;
    }
    get allowMediaQuery() {
        return true;
    }

    type = `text`

    // static PRESETS = {
    //     DEFAULT: { id: `default`, name: `Default|Défaut`, tag: `div` },
    //     PARAGRAPH: { id: `paragraph`, name: `Paragraph|Paragraphe`, tag: `p` },
    //     H1: { id: `h1`, name: `Title 1|Titre 1`, tag: `h1` },
    //     H2: { id: `h2`, name: `Title 2|Titre 2`, tag: `h2` }
    // }

    constructor(data) {
        super(data);

        if (!data) {
            this.bold = false;
            this.italic = false;
            this.underline = false;
            this.uppercase = false;
            this.letterSpacing = 1;
            this.lineHeight = 1;
            this.color = `default`;
            this.preset = `default`; // div p h1, h2, h3...
            this.format = undefined;

            this.advanced = false;
            this.leftIcon = null;
            this.rightIcon = null;
            this.prefix = {};
            this.suffix = {};
            this.widget = false;

            this.text = {};

            // Item Binding
            this.textField = null;
        }
        if (this.advanced === undefined) {
            this.advanced = false;
        }

        // if (this.color === `primary`) {
        //     this.color = `default`;
        // }

        this.newText = this.text;
    }

    // do not init in constructor because cmsWebsite is not ready yet
    initText(text = { en: `<div>Text Block</div>`, fr: `<div>Bloc de texte</div>` }, preset = `default`) {
        this.text = store.state.website.makeI18nText(text);
        this.newText = this.text;
        this.preset = preset;
    }

    getText(lang) {
        return this.text[lang];
    }

    applyChanges(language) {
        this.text = this.newText;
    }
}
