import CmsComponentImage from "@/helpers/editor/components/CmsComponentImage.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import Network from "../../../../vue-components/helpers/Network.js";

export default class CmsBlockWorldMap extends CmsBlock {
    static typeDef = {
        name: `World Map|Carte géographique`,
        type: `world-map`,
        category: `widget`,
        glyph: `fa-earth-americas`,
        isParent: false
    }

    constructor(data, page) {
        super(data, page);

        this.image = new CmsComponentImage(this.image);
        this.image.required = true;

        this.legend = new CmsComponentText(this.legend);

        if (!data) {
            this.valid = false;
            this.mapType = `ROADMAP`;
            this.location = `Montréal`;
            this.latitude = null;
            this.longitude = null;
            this.zoom = 12;
            this.marker = true;
            this.width = 1920;
            this.height = 1080;
            this.traffic = false;
            this.incidents = false;
            this.construction_sites = false;
            // Itinerary
            this.start = ``;
            this.end = ``;
            this.route = false;

            this.legend.initText({en: `Legend`, fr: `Légende` });
        }
    }

    loadMap() {
        let data = this.toJSON();
        data.type = this.mapType;
        Network.post(`/maps/static`, data, {
            responseType: `blob`,
            contentType: `application/x-www-form-urlencoded`,
            system_api: true
        })
            .then(resp => {
                console.log(resp.data);
                this.image.src = window.URL.createObjectURL(resp.data);
            })
            .catch(e => {
                console.error(e);
            })
    }

    async translate(from, to) {
        await this.legend.translate(from, to);
    }
}
