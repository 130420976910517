import CmsBlockButton from "@/helpers/editor/blocks/CmsBlockButton.js";
import CmsBlockCard from "@/helpers/editor/blocks/CmsBlockCard.js";
import CmsBlockCards from "@/helpers/editor/blocks/CmsBlockCards.js";
import CmsBlockColumns from "@/helpers/editor/blocks/CmsBlockColumns.js";
import CmsBlockColumn from "@/helpers/editor/blocks/CmsBlockColumn.js";
import CmsBlockCmsList from "@/helpers/editor/blocks/CmsBlockCmsList.js";
import CmsBlockDivider from "@/helpers/editor/blocks/CmsBlockDivider.js";
import CmsBlockFooter from "@/helpers/editor/blocks/CmsBlockFooter.js";
import CmsBlockGallery from "@/helpers/editor/blocks/CmsBlockGallery.js";
import CmsBlockIframe from "@/helpers/editor/blocks/CmsBlockIframe.js";
import CmsBlockImage from "@/helpers/editor/blocks/CmsBlockImage.js";
import CmsBlockInline from "@/helpers/editor/blocks/CmsBlockInline.js";
import CmsBlockListCategory from "@/helpers/editor/blocks/CmsBlockListCategory.js";
import CmsBlockListItem from "@/helpers/editor/blocks/CmsBlockListItem.js";
import CmsBlockMenu from "@/helpers/editor/blocks/CmsBlockMenu.js";
import CmsBlockNavigation from "@/helpers/editor/blocks/CmsBlockNavigation.js";
import CmsBlockSlideshow from "@/helpers/editor/blocks/CmsBlockSlideshow.js";
import CmsBlockSearch from "@/helpers/editor/blocks/CmsBlockSearch.js";
import CmsBlockSpacer from "@/helpers/editor/blocks/CmsBlockSpacer.js";
import CmsBlockText from "@/helpers/editor/blocks/CmsBlockText.js";
import CmsBlockTitle from "@/helpers/editor/blocks/CmsBlockTitle.js";
import CmsBlockTextAndImage from "@/helpers/editor/blocks/CmsBlockTextAndImage.js";
import CmsBlockWordPage from "@/helpers/editor/blocks/CmsBlockWordPage.js";
import CmsBlockWorldMap from "@/helpers/editor/blocks/CmsBlockWorldMap.js";
import CmsBlockYoutubeVideo from "@/helpers/editor/blocks/CmsBlockYoutubeVideo.js";

/**
 *  A bit ugly until top level await
 */
export default class CmsBlockFactory {
    constructors = [
        CmsBlockButton,
        CmsBlockCard,
        CmsBlockCards,
        CmsBlockColumn,
        CmsBlockColumns,
        CmsBlockDivider,
        CmsBlockFooter,
        CmsBlockGallery,
        CmsBlockImage,
        CmsBlockIframe,
        CmsBlockInline,
        CmsBlockListCategory,
        CmsBlockListItem,
        CmsBlockMenu,
        CmsBlockNavigation,
        CmsBlockCmsList,
        CmsBlockSlideshow,
        CmsBlockSearch,
        CmsBlockSpacer,
        CmsBlockText,
        CmsBlockTitle,
        CmsBlockTextAndImage,
        CmsBlockYoutubeVideo,
        CmsBlockWordPage,
        CmsBlockWorldMap
    ]

    constructor() {
        if (CmsBlockFactory._instance) {
            return CmsBlockFactory._instance
        }
        CmsBlockFactory._instance = this;
        window.cmsFactory = this;
    }

    getConstructor(type) {
        const constructor = this.constructors.find(c => c.typeDef.type === type);
        if (!constructor) {
            console.error(`no constructor for ${type}`);
        }
        return constructor;
    }

    new(data, page) {
        // Temporary compatibility
        if (data.type === `text` && !data.text) {
            data.type = `title`;
        }
        const constructor = this.getConstructor(data.type);
        if (constructor) {
            return new constructor(data, page);
        } else {
            throw new Error(`Invalid constructor`, data);
        }
    }
}
