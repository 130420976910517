import Network from "@/../vue-components/helpers/Network.js";

export default class ClientSideORMProvider {
    static async findById(model, id) {
        const resp = await Network.get(model.constructor.endpoint + `/` + id);
        return resp.data;
    }

    static async findOne(model, filter) {
        throw new Error(`Cannot findOne client side.`)
    }

    static async find(model, filter) {
        const resp = await Network.get(model.constructor.endpoint, filter);
        return resp.data;
    }

    static async save(model, options = {}) {
        const baseUrl = options.baseUrl || ``;
        const updateMode = model.getOptions().updateMode;

        const body = model._clone(model);

        if (updateMode) {
            body.$operations = model.getUpdateQuery();
        }

        const resp = await Network.post(model.constructor.endpoint + `/save`, body, {baseUrl});
        if (resp.data.id) {
            model.id = resp.data.id;
        }
        return resp.data;
    }

    static getAuthUser(options = {}) {
        return options.user;
    }
}
