import CmsComponentButton from "@/helpers/editor/components/CmsComponentButton.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import {tr} from "../../../../vue-components/helpers/i18n.js";

export default class CmsBlockButton extends CmsBlock {
    static typeDef = {
        name: `Button|Bouton`,
        glyph: `fal fa-rectangle-wide`,
        type: `button`,
        category: `base`,
        isParent: false
    }

    get displayName() {
        return this.extractHtmlText(tr(this.button.text));
    }

    constructor(data, page) {
        super(data, page);

        this.title = new CmsComponentText(this.title);

        this.subtitle = new CmsComponentText(this.subtitle);

        this.description = new CmsComponentText(this.description);

        this.button = new CmsComponentButton(this.button);

        if (!data) {
            this.title.initText({en: `Title`, fr: `Titre`}, `h1`);
            this.title.active = false;

            this.subtitle.initText({en: `Subtitle`, fr: `Sous-titre`}, `h4`);
            this.subtitle.active = false;

            this.description.initText({en: `Description`, fr: `Description`});
            this.description.active = false;

            this.button.initText({en: `Button`, fr: `Bouton`});
        }
    }

    async translate(from, to) {
        await this.title.translate(from, to);
        await this.subtitle.translate(from, to);
        await this.description.translate(from, to);
        await this.button.translate(from, to);
    }
}
