import Translation from "./Translation.js";

export default class TypeFactory {
    static new(typeName, value) {
        switch (typeName.toLowerCase()) {
            case `translation`:
                return new Translation(value);
            case `date`:
                return new Date(value);
            case `array`:
                if (!Array.isArray(value)) {
                    throw new Error(`Not an array`)
                }
                return value;
            case `number`:
                return Number(value);
            case `object`:
                return value;
            case `string`:
                return String(value).trim();
            case `boolean`:
                return !!value;
            default:
                return value;
        }
    }

    static isEmpty(value) {
        // 0 and false are valid values
        if (value === null || value === undefined) {
            return true;
        }

        switch (value?.constructor?.name?.toLowerCase()) {
            case `array`:
                return value.length === 0;
            case `number`:
                return isNaN(value);
            case `object`:
                return Object.keys(value).length === 0;
            case `translation`:
                return value.isEmpty();
            case `boolean`:
                return false;
            default:
                return !value;
        }
    }
}
