import {type} from "../abstract/Model.js";

export default class DateMixin {
    createdAt = {
        [type]: Date,
        required: true,
        default: () => new Date(),
        defaultOnly: true,
        insertOnly: true,
        index: -1
    }

    updatedAt = {
        [type]: Date,
        required: true,
        default: () => new Date(),
        defaultOnly: true
    }
}
