/* global chrome */

export default class Storage {
    static set(key, value) {
        return new Promise(resolve => {
            if (typeof chrome !== `undefined` && chrome.storage) {
                const obj = {};
                obj[key] = value;
                chrome.storage.sync.set(obj, resolve)
            } else {
                localStorage.setItem(key, value);
                resolve();
            }
        });
    }

    static get(key) {
        return new Promise((resolve, reject) => {
            if (typeof chrome !== `undefined` && chrome.storage) {
                chrome.storage.sync.get(key, storage => {
                    if (storage) {
                        const result = storage[key];
                        if (!result) {
                            reject(`Storage key "` + key + `" does not exist`);
                            return;
                        }

                        resolve(result);
                    }
                })
            } else {
                const result = localStorage.getItem(key);
                if (!result) {
                    reject(`Storage key "` + key + `" does not exist`);
                    return;
                }

                resolve(result);
            }
        });
    }

    static remove(key) {
        return new Promise(resolve => {
            if (typeof chrome !== `undefined` && chrome.storage) {
                const obj = {};
                obj[key] = null;
                chrome.storage.sync.set(obj, resolve)
            } else {
                localStorage.removeItem(key);
                resolve();
            }
        });
    }

    static clearAll() {
        return new Promise(resolve => {
            if (typeof chrome !== `undefined` && chrome.storage) {
                chrome.storage.sync.clear()
            } else {
                localStorage.clear();
            }

            resolve();
        });
    }
}
