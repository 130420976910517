import CmsLink from "@/helpers/cms/CmsLink.js";
import CmsComponent from "@/helpers/editor/components/CmsComponent.js";

export default class CmsComponentSocialLinks extends CmsComponent {
    get allowMediaQuery() {
        return true;
    }

    get DISPLAY_TYPES() {
        return {
            TEXT: `text`,
            TEXT_ICON_LEFT: `text-icon-left`,
            TEXT_ICON_RIGHT: `text-icon-right`,
            TEXT_ICON_TOP: `text-icon-top`,
            ICON: `icon`
        }
    }

    get BUTTON_TYPES() {
        return {
            SOLID: `text`,
            OUTLINE: `outline`,
            LIGHT: `light`,
            TEXT: `text`
        }
    }

    get COMPONENT_TYPES() {
        return {
            DEFAULT: `default`,
            NAV_ITEMS: `buttons`
        }
    }

    type = `social-links`

    constructor(data) {
        super(data);

        if (!data) {
            this.links = {};
            this.component = this.COMPONENT_TYPES.DEFAULT;
            this.display = this.DISPLAY_TYPES.TEXT;
            this.mediaQuery.mobile.layout = this.DISPLAY_TYPES.TEXT_ICON_LEFT;
            this.buttonType = this.BUTTON_TYPES.TEXT;
            this.layout = `horizontal`;
            this.mediaQuery.mobile.layout = `vertical`;

            this.spacing = `5px`;
            this.size = `md`;
            this.buttonType = `solid`;

            this.borderRadius = `var(--border-radius)`;

            this.fillSize = false;
            this.linkSizeMode = `auto`;
            this.linkSize = `100px`;

            this.separator = false;
            this.separatorColor = `#777`;
            this.separatorWidth = `2px`;
        } else {
            // Old compatibility
            if (!this.linkSize) {
                this.linkSizeMode = this.widthSizeMode;
                this.linkSize = this.widthSize;
                this.fillSize = this.fillHeight;
            }
        }

        if (!this.links) {
            this.links = {};
        }
    }

    linkHref(link) {
        if (link.type === `email`) {
            return `mailto:${link.value}`;
        } else if (link.type === `tel`) {
            return `tel:${link.value}`;
        }
        return `https://${link.prefix}${link.value}`;
    }
}
