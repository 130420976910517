import store from "@/store.js";

export default class StyleHelper {

    static getResponsiveStyleProp(style, key) {
        if (!style) {
            return null;
        }

        // Override properties based on the layout
        if (store.state.displayMode === `mobile` && typeof style.mobile === `object` && style.mobile[key]) {
            return style.mobile[key];
        }

        if (store.state.displayMode === `tablet` && typeof style.tablet === `object` && style.tablet[key]) {
            return style.tablet[key];
        }

        if (store.state.displayMode === `kiosk` && typeof style.kiosk === `object` && style.kiosk[key]) {
            return style.kiosk[key];
        }
        return style[key];
    }

    static mergeResponsiveStyle(style) {
        for (let key in style) {
            if (key === `style`) {
                style[key] = StyleHelper.mergeStyleProperties(style[key]);
            } else if (typeof style[key] === `object`) {
                style[key] = StyleHelper.mergeResponsiveStyle(style[key]);
            }
        }
        return style;
    }

    static mergeStyleProperties(style) {
        let responsiveStyle = {};

        for (let key in style) {
            if (key === `mobile` || key === `tablet` || key === `kiosk`)
                continue;

            responsiveStyle[key] = style[key];
        }

        // Override properties based on the layout
        const mobileStyle = style.mobile;
        if (store.state.displayMode === `mobile` && mobileStyle) {
            for (let key in mobileStyle) {
                responsiveStyle[key] = mobileStyle[key];
            }
        }

        const tabletStyle = style.tablet;
        if (store.state.displayMode === `tablet` && tabletStyle) {
            for (let key in tabletStyle) {
                responsiveStyle[key] = tabletStyle[key];
            }
        }

        const kioskStyle = style.kiosk;
        if (store.state.displayMode === `kiosk` && kioskStyle) {
            for (let key in kioskStyle) {
                responsiveStyle[key] = kioskStyle[key];
            }
        }
        return responsiveStyle;
    }
}
