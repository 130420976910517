import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsBlockTextAndImage from "@/helpers/editor/blocks/CmsBlockTextAndImage.js";

export default class CmsBlockSlideshow extends CmsBlock {
    static typeDef = {
        name: `Slideshow|Diaporama`,
        type: `slideshow`,
        category: `widget`,
        glyph: `fa-rectangle-vertical-history`,
        isParent: true
    }

    constructor(data, page) {
        super(data, page);

        if (!data) {
            this.transition = `slide`; // slide | fade | none
            this.controls = true;
            this.indicators = true;
            this.interval = 5000;

            this.addChild(new CmsBlockTextAndImage());
            this.addChild(new CmsBlockTextAndImage());
        }
    }
}
