import EditorPadding from "@/helpers/cms/EditorPadding.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";

export default class CmsBlockSpacer extends CmsBlock {
    static typeDef = {
        name: `Spacer|Espacement`,
        type: `spacer`,
        category: `base`,
        glyph: `fa-file-dashed-line`,
        isParent: false
    }

    constructor(data, page) {
        super(data, page);

        if (!data) {
            this.size = `50px`;
            this.padding = new EditorPadding(0);
        }
    }
}
