import CmsComponentImage from "@/helpers/editor/components/CmsComponentImage.js";
import CmsComponentNavigationLinks from "@/helpers/editor/components/CmsComponentNavigationLinks.js";
import CmsComponentSocialLinks from "@/helpers/editor/components/CmsComponentSocialLinks.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";

export default class CmsBlockFooter extends CmsBlock {
    static typeDef = {
        name: `Footer|Bas de page`,
        type: `footer`,
        category: `navigation`,
        glyph: `fa-browser fa-flip-vertical`,
        isParent: false,
        hidden: true
    }

    // get layoutPresets() {
    //     return [
    //         {
    //             id: `default`,
    //             name: `Default|Défaut`
    //         },
    //         {
    //             id: `row-left`,
    //             name: `Row Left|Ligne à gauche`
    //         },
    //         {
    //             id: `row-right`,
    //             name: `Row Right|Ligne à droite`
    //         }
    //     ]
    // }

    constructor(data) {
        super(data);

        this.logo = new CmsComponentImage(this.logo);

        this.linksTitle = new CmsComponentText(this.linksTitle);
        this.links = new CmsComponentNavigationLinks(this.links);

        this.socialLinksTitle = new CmsComponentText(this.socialLinksTitle);
        this.socialLinks = new CmsComponentSocialLinks(this.socialLinks);

        this.copyright = new CmsComponentText(this.copyright);

        if (!data) {
            this.layout = `row-left`;
            this.padding.top = 50;
            this.padding.bottom = 50;

            this.logo.maxWidth = 150;
            this.logo.maxHeight = 40;

            this.links.spacing = `0px`;
            this.links.useMenuNavigation = true;

            this.linksTitle.initText({ en: `Links`, fr: `Liens` });
            this.socialLinksTitle.initText({ en: `Stay in Touch`, fr: `Restez à l'affut` }, `h3`);
            this.copyright.initText({ en: `All Rights Reserved Eye-In Media Copyright 2024`, fr: `Tous droits réservés, Eye-In Média 2024` });
        }
    }
}
