import CmsBaseClass from "@/helpers/cms/CmsBaseClass.js";
import store from "@/store.js"

export default class CmsMediaQuery extends CmsBaseClass{
    constructor(data/*, enableLanguage = false*/) {
        super(data);

        if (this.enabled) {
            this.expanded = this.enabled;
            delete this.enabled;
        }
        if (!data) {
            // To customize visibility per CmsComponent
            this.expanded = false;

            this.mobile = {
                visible: true
            }
            this.tablet = {
                visible: true
            }
            // desktop is the default layout
            this.desktop = {
                visible: true
            }
            this.kiosk = {
                visible: true
            }
            this.wheelchair = {
                visible: true
            }
        }
        if (!this.wheelchair) {
            this.wheelchair = {
                visible: true
            }
        }
        // if (enableLanguage && !this.languages) {
        //     this.languages = {};
        //     for (let lang in store.state.website.settings.languages) {
        //         this.languages[lang] = {
        //             visible: true
        //         }
        //     }
        // }
    }

    createProxy(owner, name = `default`) {
        let proxy = new Proxy(owner, {
            get(target, prop, receiver) {
                if (store.state.displayMode === `desktop`) {
                    if (prop === `visible`) {
                        return target.mediaQuery.desktop[prop];
                    } else {
                        return Reflect.get(target, prop, receiver);
                    }
                } else {
                    if (target.mediaQuery[store.state.displayMode][prop] === undefined) {
                        if (prop === `visible`) {
                            return target.mediaQuery.desktop[prop];
                        } else {
                            return Reflect.get(target, prop, receiver);
                        }
                    } else {
                        return target.mediaQuery[store.state.displayMode][prop];
                    }
                }
            },
            set(target, prop, value) {
                if (store.state.displayMode === `desktop` || prop === `visible`) {
                    target[prop] = value;
                } else {
                    if (target.mediaQuery[store.state.displayMode][prop] === undefined) {
                        target[prop] = value;
                    } else {
                        target.mediaQuery[store.state.displayMode][prop] = value;
                    }
                }
                return true;
            }
        });
        return proxy;
    }

    toggle() {
        this.expanded = !this.expanded;
    }
}
