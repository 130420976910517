export default class MapBaseClass {
    constructor(data) {
        this.id = generateGuid();
        if (data) {
            this.loadSaveCompletely(data);
        }
    }

    get ignoredFields() {
        return []
    }

    loadSaveCompletely(data) {
        for (let key in data) {
            this[key] = data[key];
        }
    }

    loadSave(data) {
        for (let key in this) {
            if (data[key] === undefined) {
                console.error(`Missing value for ${key}`);
            }
            if (this.ignoredFields.indexOf(key) >= 0) {
                continue;
            }
            if (key !== `ignoredFields` && typeof this[key] !== `function`) {
                this[key] = data[key];
            }
        }
        this.postLoad();
    }

    duplicate(item) {
        this.loadSaveCompletely(item.toJSON());
        this.id = generateGuid();
    }

    postLoad() {
        // to override
    }

    toJSON() {
        let data = {};
        for (let key in this) {
            if (this.ignoredFields.indexOf(key) >= 0) {
                continue;
            }
            if (key !== `ignoredFields` && typeof this[key] !== `function`) {
                data[key] = this[key];
            }
        }
        return data;
    }

    delete() {
        if (this.deleted === false) {
            this.beforeDelete();
            this.deleted = true;
        } else {
            console.error(`Already Deleted`);
        }
    }

    beforeDelete() {
        // to override
    }
}
