import CmsComponentButton from "@/helpers/editor/components/CmsComponentButton.js";
import CmsComponentImage from "@/helpers/editor/components/CmsComponentImage.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import {tr} from "../../../../vue-components/helpers/i18n.js";

export default class CmsBlockTextAndImage extends CmsBlock {
    static typeDef = {
        name: `Text and Image|Texte et image`,
        type: `text-and-image`,
        category: `preset`,
        glyph: `fa-block-quote`,
        isParent: false
    }

    get displayName() {
        if (this.item) {
            return tr(this.item.name);
        } else {
            return this.extractHtmlText(tr(this.title.text));
        }
    }

    get layoutPresets() {
        return [
            {
                id: `default`,
                name: `Default|Défaut`
            },
            {
                id: `row-left`,
                name: `Row Left|Ligne à gauche`
            },
            {
                id: `row-right`,
                name: `Row Right|Ligne à droite`
            }
        ]
    }

    constructor(data, page) {
        super(data, page);

        this.image = new CmsComponentImage(this.image);

        this.title = new CmsComponentText(this.title);
        this.subtitle = new CmsComponentText(this.subtitle);

        this.description = new CmsComponentText(this.description);

        this.button = new CmsComponentButton(this.button);

        if (!data) {
            this.layout = `row-left`;
            this.title.initText({ en: `Title`, fr: `Titre` }, `h1`);

            this.subtitle.active = false;
            this.subtitle.initText({ en: `Subtitle`, fr: `Sous-titre` }, `h3`);

            this.description.initText({en: `And Text`, fr: `Et Texte` });

            this.button.active = false;
            this.button.initText({en: `Button`, fr: `Bouton` });
        }
    }
}
