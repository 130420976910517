import Vue from 'vue';
import StyleHelper from "@/helpers/StyleHelper.js";

Vue.directive(`style`, {
    bind(el, binding) {
        if (binding.value) {
            el.setAttribute(`style`, StyleHelper.mergeResponsiveStyle(binding.value));
        }
    },
    update: function(el, binding, vnode) {
        if (binding.value) {
            el.setAttribute(`style`, StyleHelper.mergeResponsiveStyle(binding.value));
        }
    }
});
