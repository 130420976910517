import CmsComponentButton from "@/helpers/editor/components/CmsComponentButton.js";
import CmsComponentGlyph from "@/helpers/editor/components/CmsComponentGlyph.js";
import CmsComponentImage from "@/helpers/editor/components/CmsComponentImage.js";
import CmsComponentLink from "@/helpers/editor/components/CmsComponentLink.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import {tr} from "../../../../vue-components/helpers/i18n.js";

export default class CmsBlockCard extends CmsBlock {
    static typeDef = {
        name: `Card|Carte`,
        glyph: `fas fa-address-card`,
        type: `card`,
        category: `base`,
        isParent: false
    }

    get displayName() {
        if (this.item) {
            return tr(this.item.name);
        } else {
            return this.extractHtmlText(tr(this.title.text));
        }
    }

    constructor(data, page) {
        super(data, page);

        // tmp cleanup
        if (typeof this.link === `object` && this.link.textField === undefined) {
            delete this.link;
        }
        this.link = new CmsComponentLink(this.link);
        this.image = new CmsComponentImage(this.image);
        this.glyph = new CmsComponentGlyph(this.glyph);
        this.title = new CmsComponentText(this.title);
        this.subtitle = new CmsComponentText(this.subtitle);
        this.description = new CmsComponentText(this.description);
        this.mainButton = new CmsComponentButton(this.mainButton);
        this.altButton = new CmsComponentButton(this.altButton);

        if (!data) {
            this.glyph.active = false;

            this.title.initText({en: `Title`, fr: `Titre`}, `h3`);
            this.title.active = true;

            this.subtitle.initText({en: `Subtitle`, fr: `Sous-titre`}, `h6`);
            this.subtitle.active = false;

            this.description.initText({en: `Description`, fr: `Description`});
            this.description.active = false;

            this.mainButton.initText({en: `Main Action`, fr: `Action Principale`});
            this.mainButton.active = false;

            this.altButton.initText({en: `Alt Action`, fr: `Action secondaire`});
            this.altButton.active = false;
        }
        if (this.glyph && !this.glyph.glyph && !this.glyph.glyphField) {
            this.glyph.active = false;
        }
    }

    async translate(from, to) {
        await this.title.translate(from, to);
        await this.subtitle.translate(from, to);
        await this.description.translate(from, to);
    }
}
