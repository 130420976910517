import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsBlockColumn from "@/helpers/editor/blocks/CmsBlockColumn.js";

export default class CmsBlockColumns extends CmsBlock {
    static typeDef = {
        name: `Columns|Colonnes`,
        glyph: `fa-line-columns`,
        type: `columns`,
        category: `layout`,
        isParent: true
    }

    get childClass() {
        return [CmsBlockColumn];
    }

    get maxChildren() {
        return 8;
    }

    constructor(data, page) {
        super(data, page);

        if (!data) {
            this.spacing = `10px`;
            this.columns = 2;
            this.mediaQuery.mobile.columns = 1;

            this.addChild(new CmsBlockColumn());
            this.addChild(new CmsBlockColumn());
        }
    }
}
