import TypeFactory from "../types/TypeFactory.js";

globalThis.F_TRIM = `trim`;
globalThis.F_LOWER = `lower`;

// Never export or expose these symbols outside of this class, unless you understand deeply the whole code and what you are doing
const _fields = Symbol(`_fields`);
const _options = Symbol(`_options`);
const _fieldsProperties = Symbol(`_fieldsProperties`);
const _id = Symbol(`_id`);

const _set = Symbol(`_set`);
const _setOnInsert = Symbol(`_setOnInsert`);
const _inc = Symbol(`_inc`);
const _mul = Symbol(`_mul`);
const _push = Symbol(`_push`);
const _pop = Symbol(`_pop`);
const _unset = Symbol(`_unset`);

const allOperators = [
    _set,
    _setOnInsert,
    _inc,
    _mul,
    _push,
    _pop,
    _unset
];

const _proxyValue = Symbol(`_proxyValue`);
export const type = Symbol(`type`);

const debugEnabled = false;

function debug() {
    if (debugEnabled) {
        console.log(...arguments);
    }
}

export default class AbstractModel {
    // used to filter (the upsert in save method for example)
    static primaryKey = [`id`];
    static extractFields = {name: 1};

    [_id] = null; // model id (mandatory _id in MongoDB even if it's not a primary key)
    [_fields] = {}; // true model fields are saved here (except for id/_id)
    [_fieldsProperties] = {}; // field definitions set during the subclass definition (type, required, etc.)
    [_options] = {}; // options added to the constructor 2nd argument

    // MongoDB operators (non-exhaustive, add more if needed)
    [_set] = {};
    [_setOnInsert] = {};
    [_inc] = {};
    [_mul] = {};
    [_push] = {};
    [_pop] = {};
    [_unset] = {};

    constructor(fields = {}, options = {}) {
        if (fields.hasOwnProperty(`$operations`)) {
            options.updateMode = true;
            for (const $operator of allOperators) {
                const operatorString = $operator.toString().replace(/Symbol\(_(\w+)\)/, `$$$1`); // _set.toString() === "Symbol(_set)"

                if (fields.$operations.hasOwnProperty(operatorString)) {
                    this[$operator] = fields.$operations[operatorString];
                }
            }
        }

        this[_fields] = fields;

        // remove id or _id from this[$fields]
        if (this[_fields].id || this[_fields]._id) {
            this[_id] = this[_fields].id || this[_fields]._id;
            delete this[_fields]._id;
            delete this[_fields].id;
        }

        this[_options] = options;

        // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
        return new Proxy(this, {
            get(self, key, receiver) {
                if (key === `id`) {
                    return self[_id];
                }

                if (self[_fields].hasOwnProperty(key)) {
                    return self[_fields][key];
                }

                return Reflect.get(...arguments);
            },
            set(self, key, value) {
                if (typeof key === `symbol` || key[0] === `_`) {
                    return Reflect.set(...arguments);
                } else if (typeof value === `object` && value?.hasOwnProperty(type)) {
                    // only for fields definition inside Class
                    self[_fieldsProperties][key] = value;
                    self.$set(key, self[_fields][key], {defineProperty: true});
                    return true;
                } else {
                    return self.$set(key, value);
                }
            },
            // trap instance field definition (fields defined outside the constructor of the subclass)
            defineProperty(self, key, descriptor) {
                if (typeof descriptor.value === `object` && descriptor.value?.hasOwnProperty(type)) {
                    self[_fieldsProperties][key] = descriptor.value;
                    self.$set(key, self[_fields][key], {defineProperty: true});
                    return true;
                }
                return Reflect.defineProperty(self, key, descriptor);
            },
            deleteProperty(self, key) {
                self[_unset][key] = ``;
                delete self[_fields][key];
                return Reflect.deleteProperty(...arguments);
            },
            has(self, key) {
                // Reject all symbols and protected keys (_) from outside
                if (typeof key === `symbol` || key[0] === `_`) {
                    console.warn(`Model: getting inside Proxy.has trap with a key symbol or protected key should never happen`);
                    return false;
                }

                if (key === `id`) {
                    return self[_id];
                }

                if (self[_fields].hasOwnProperty(key)) {
                    return true;
                }

                return Reflect.has(...arguments);
            },
            ownKeys(self) {
                if (self[_id]) {
                    return Object.keys({id: self[_id], ...self[_fields]});
                }
                return Object.keys(self[_fields]);
            },
            getOwnPropertyDescriptor(self, key) {
                if ((typeof key === `symbol` || key[0] === `_`) && self.hasOwnProperty(key)) {
                    return {
                        writable: true,
                        enumerable: false,
                        configurable: true
                    };
                } else if (key === `id` && self[_id]) {
                    return {
                        writable: true,
                        enumerable: true,
                        configurable: true
                    };
                }

                return Object.getOwnPropertyDescriptor(self[_fields], key);
            }
        });
    }

    $get(key) {
        // key can have dot format for deep get: `user.dateOfBirth.day` for example
        if (typeof key === `string` && key.includes(`.`)) {
            const keys = key.split(`.`);

            let obj = this;
            for (const key of keys) {
                if (!obj.hasOwnProperty(key)) {
                    return undefined;
                }
                obj = obj[key];
            }

            return obj;
        } else if (this[_fields].hasOwnProperty(key)) {
            return this[_fields][key];
        } else {
            return undefined;
        }
    }

    $set(key, value, options = {force: false}) {
        debug(`$set(${key}, ${value})`)
        if (key === `_id` || key === `id`) {
            this[_id] = value;
            return true;
        }

        if (typeof key !== `string`) {
            throw new Error(`[${this.constructor.name}] key must be a string, got ${typeof key}`);
        }

        if (typeof value === `object`) {
            value = this.$reactive(key, value);
        }

        let operationAlreadyDone = options.operationDone;

        // key can have dot format but in this case no validation is done
        if (key.includes(`.`)) {
            if (options.valueAlreadySet && !operationAlreadyDone) {
                this._addDBOperation(_set, key, value);
                return true;
            }

            const keys = key.split(`.`);
            const lastKey = keys.pop();

            let obj = this[_fields];
            for (const key of keys) {
                if (obj.hasOwnProperty(key)) {
                    obj = obj[key];
                } else {
                    obj[key] = {}
                    obj = obj[key];
                }
            }

            obj[lastKey] = value;

            if (!operationAlreadyDone && !options.force) {
                this._addDBOperation(_set, key, value);
            }
            return true;
        }

        // if force option, no validation is done, but no operation is done either
        if (options.force) {
            this[_fields][key] = value;
            return true;
        }

        // if we don't know this field, we just add it
        if (!this[_fieldsProperties].hasOwnProperty(key)) {
            this[_fields][key] = value;

            if (!operationAlreadyDone) {
                this._addDBOperation(_set, key, value);
            }

            return true;
        }

        // From here we know the field properties are defined in this[$fieldsProperties][key]

        const fieldProperties = this[_fieldsProperties][key];

        // defaultOnly means value is equal to the default value no matter what is set by the user
        if (fieldProperties.defaultOnly) {
            if (!this._hasDefaultValue(key)) {
                throw new Error(`Cannot find default for "${key}" with defaultOnly: true`);
            }
            value = this._getDefaultValue(key);
        } else if (typeof value === `undefined` && this._hasDefaultValue(key)) {
            value = this._getDefaultValue(key);
            this._addDBOperation(_setOnInsert, key, value); // We do not want to erase DB value if not changed manually, and upsert is actually an update
            operationAlreadyDone = true;
        }

        // if value is undefined and not default value, we stop here there is nothing to do
        if (typeof value === `undefined`) {
            return true;
        }

        // Type validation
        const fieldType = fieldProperties[type]?.name;
        try {
            value = TypeFactory.new(fieldType, value);
        } catch (e) {
            throw new Error(`[${this.constructor.name}] Cannot assign type "${typeof value}" to "${key}" with type "${fieldType}"`);
        }

        // Flags
        value = this._applyFlags(value, fieldProperties.flags);

        if (this[_options].updateMode) {
            // incrementOnly, multiplyOnly, pushOnly
            if (fieldProperties.incrementOnly && options.operator !== _inc && !operationAlreadyDone) {
                if (options.defineProperty) {
                    console.warn(`[${this.constructor.name}] Cannot set "${key}" in constructor with incrementOnly: true`);
                } else {
                    this._addDBOperation(_inc, key, value - this[_fields][key]); // necessary to be able to write model.insertOnlyProp += 1 or model.insertOnlyProp++ or model.insertOnlyProp--
                }
                operationAlreadyDone = true;
            } else if (fieldProperties.multiplyOnly && options.operator !== _mul && !operationAlreadyDone) {
                if (options.defineProperty) {
                    console.warn(`[${this.constructor.name}] Cannot set "${key}" in constructor with multiplyOnly: true`);
                } else {
                    this._addDBOperation(_mul, key, value / this[_fields][key]); // necessary to be able to write model.insertOnlyProp *= 2 or model.insertOnlyProp /= 2
                }
                operationAlreadyDone = true;
            } else if (fieldProperties.pushOnly && options.operator !== _push && !operationAlreadyDone) {
                if (options.defineProperty) {
                    console.warn(`[${this.constructor.name}] Cannot set "${key}" in constructor with pushOnly: true`);
                } else {
                    throw new Error(`[${this.constructor.name}] Cannot set "${key}" array property with pushOnly: true`) // Do not authorize model.arrayProp = value to be equivalent to model.arrayProp.push(value), you can use model.$set(`arrayProp`, arrayValue, {force: true}) instead
                }
                operationAlreadyDone = true;
            }
        }

        // User defined validation function
        if (fieldProperties.validation) {
            if (typeof fieldProperties.validation !== `function`) {
                throw new Error(`[${this.constructor.name}] validation must be a function for field "${key}"`);
            }

            if (!fieldProperties.validation(value)) {
                throw new Error(`[${this.constructor.name}] Cannot assign "${value}" to field "${key}", validation function returned false`);
            }
        }

        // Adding the value to the model
        this[_fields][key] = value;

        if (operationAlreadyDone) {
            return true;
        }

        // Fill the correct operator
        if (fieldProperties.insertOnly) {
            this._addDBOperation(_setOnInsert, key, value);
        } else {
            this._addDBOperation(_set, key, value);
        }

        return true;
    }

    $reactive(key, value) {
        debug(`$reactive(${key})`);
        const self = this;

        // We only need operations in updateMode, else everything is just in $set
        if (!this[_options].updateMode) {
            return value;
        }

        if (Array.isArray(value)) {
            if (value[_proxyValue]) {
                return value;
            }

            return new Proxy(this._clone(value), {
                get(target, prop) {
                    if (prop === _proxyValue) {
                        return true;
                    }

                    const traceMethods = [`push`, `unshift`, `pop`, `shift`];
                    if (traceMethods.includes(prop)) {
                        return item => {
                            switch (prop) {
                                case `push`:
                                    self.$push(key, item);
                                    return true;
                                case `unshift`:
                                    self.$unshift(key, item);
                                    return true;
                                case `pop`:
                                    self.$pop(key);
                                    return true;
                                case `shift`:
                                    self.$shift(key);
                                    return true;
                            }
                        }
                    }

                    return target[prop];
                }
            });
        } else if (value?.constructor?.name === `Object`) {
            if (value[_proxyValue]) {
                return value;
            }

            return new Proxy(this._clone(value), {
                get(target, key) {
                    if (key === _proxyValue) {
                        return true;
                    }

                    return Reflect.get(...arguments);
                },
                set(target, prop, value) {
                    target[prop] = value;
                    const rootKey = key.split(`.`).shift();
                    if (typeof self[_set][rootKey] !== `undefined`) {
                        return true;
                    }
                    return self.$set(`${key}.${prop}`, value, {valueAlreadySet: true});
                }
            });
        } else {
            return value;
        }
    }

    $setAll(obj, force = false) {
        for (const key in obj) {
            this.$set(key, obj[key], {force: true});
        }
    }

    $increment(key, value) {
        const oldValue = this.$get(key);
        let newValue;
        if (typeof oldValue === `undefined`) {
            newValue = value;
        } else {
            newValue = oldValue + value;
        }

        if (!this[_options].updateMode) {
            return this.$set(key, newValue);
        }

        if (typeof this[_inc][key] === `undefined`) {
            this._addDBOperation(_inc, key, value);
        } else {
            this._addDBOperation(_inc, key, this[_inc][key] + value);
        }

        return this.$set(key, newValue, {operator: _inc, operationDone: true});
    }

    $incrementAll(obj) {
        for (const key in obj) {
            this.$increment(key, obj[key]);
        }
    }

    $multiply(key, value) {
        const oldValue = this.$get(key);
        let newValue;
        if (typeof oldValue === `undefined`) {
            newValue = value;
        } else {
            newValue = oldValue * value;
        }

        if (!this[_options].updateMode) {
            return this.$set(key, newValue);
        }

        if (typeof this[_mul][key] === `undefined`) {
            this._addDBOperation(_mul, key, value);
        } else {
            this._addDBOperation(_mul, key, this[_mul][key] * value);
        }

        this.$set(key, newValue, {operator: _mul, operationDone: true});
    }

    $multiplyAll(obj) {
        for (const key in obj) {
            this.$multiply(key, obj[key]);
        }
    }

    $push(key, value) {
        const oldValue = this.$get(key);
        let newValue;

        if (typeof oldValue === `undefined`) {
            newValue = [value];
        } else {
            newValue = [...oldValue, value];
        }

        if (!this[_options].updateMode) {
            return this.$set(key, newValue);
        }

        let operationValue = {$each: []};

        if (typeof this[_push][key] === `undefined`) {
            if (typeof this[_set][key] === `undefined`) {
                operationValue.$each = [value];
            } else {
                operationValue.$each = [...this[_set][key], value];
                delete this[_set][key];
            }
            this._addDBOperation(_push, key, operationValue);
        } else if (typeof this[_push][key].$position !== `undefined`) {
            throw new Error(`[${this.constructor.name}] Cannot push "${key}" which has already been unshifted. Set a new array instead.`);
        } else {
            operationValue.$each = [...this[_push][key].$each, value];
            this._addDBOperation(_push, key, operationValue);
        }

        this.$set(key, newValue, {operator: _push, operationDone: true});
    }

    $unshift(key, value) {
        const oldValue = this.$get(key);
        let newValue;
        if (typeof oldValue === `undefined`) {
            if (typeof this[_set][key] !== `undefined`) {
                newValue = [value, ...this[_set][key]];
                delete this[_set][key];
            } else {
                newValue = [value];
            }
        } else {
            newValue = [value, ...oldValue];
        }

        if (!this[_options].updateMode) {
            return this.$set(key, newValue);
        }

        let operationValue = {$each: [], $position: 0};

        if (typeof this[_push][key] === `undefined`) {
            if (typeof this[_set][key] === `undefined`) {
                operationValue.$each = [value];
            } else {
                operationValue.$each = [value, ...this[_set][key]];
                delete this[_set][key];
            }
            this._addDBOperation(_push, key, operationValue);
        } else if (typeof this[_push][key].$position === `undefined` && this[_push][key].$each.length > 0) {
            throw new Error(`[${this.constructor.name}] Cannot unshift "${key}" which has already been pushed. Set a new array instead.`);
        } else {
            operationValue.$each = [value, ...this[_push][key].$each];
            this._addDBOperation(_push, key, operationValue);
        }

        this.$set(key, newValue, {operator: _push, operationDone: true});
    }

    $pop(key) {
        const oldValue = this.$get(key);
        let newValue;
        if (typeof oldValue === `undefined`) {
            newValue = [];
        } else {
            newValue = oldValue.slice(0, oldValue.length - 1);
        }

        if (!this[_options].updateMode) {
            return this.$set(key, newValue);
        }

        if (typeof this[_pop][key] === `undefined`) {
            this._addDBOperation(_pop, key, 1);
        } else if (typeof this[_pop][key] !== `undefined`) {
            throw new Error(`[${this.constructor.name}] Only one pop() or shift() operation for "${key}" is allowed. Set a new array instead.`);
        } else {
            this._addDBOperation(_pop, key, this[_pop][key] + 1);
        }

        this.$set(key, newValue, {operator: _pop, operationDone: true});
    }

    $shift(key) {
        const oldValue = this.$get(key);
        let newValue;
        if (typeof oldValue === `undefined`) {
            newValue = [];
        } else if (typeof this[_pop][key] !== `undefined`) {
            throw new Error(`[${this.constructor.name}] Only one pop() or shift() operation for "${key}" is allowed. Set a new array instead.`);
        } else {
            newValue = oldValue.slice(1, oldValue.length);
        }

        if (!this[_options].updateMode) {
            return this.$set(key, newValue);
        }

        if (typeof this[_pop][key] === `undefined`) {
            this._addDBOperation(_pop, key, -1);
        } else {
            this._addDBOperation(_pop, key, this[_pop][key] - 1);
        }

        this.$set(key, newValue, {operator: _pop, operationDone: true});
    }

    $unset(key) {
        if (this[_fields][key]) {
            delete this[_fields][key];
            this._addDBOperation(_unset, key, ``);
        }
    }

    $unsetAll(keys) {
        if (keys === null)
            return;

        if (typeof keys === `object`) {
            keys = Object.keys(keys);
        }
        for (const key of keys) {
            this.$unset(key);
        }
    }

    $cancelAllChanges() {
        for (const $operator of allOperators) {
            this[$operator] = {};
        }
    }

    async save(options = {}) {
        this.constructor._checkModelValidity();

        this._checkValidity();

        if (!this[_options].updateMode) { // if not in update mode, only set or setOnInsert are used. So no need to check other operators
            // All fields that have not been updated are not defined are not in _set or _setOnInsert
            for (const key in this[_fields]) {
                if (!this[_fieldsProperties].hasOwnProperty(key)) {
                    this[_set][key] = this[_fields][key];
                }
            }
        }

        if (options.refresh) {
            const refreshed = await globalThis.eyeInORMProvider.save(this, options);
            this.$setAll(refreshed, true);
        } else {
            await globalThis.eyeInORMProvider.save(this, options);
        }

        this.$cancelAllChanges();
    }

    async saveAndRefresh(options = {}) {
        return this.save({refresh: true, ...options});
    }

    getUpdateQuery() {
        return {
            $set: this[_set],
            $setOnInsert: this[_setOnInsert],
            $inc: this[_inc],
            $mul: this[_mul],
            $push: this[_push],
            $pop: this[_pop],
            $unset: this[_unset]
        };
    }

    getOptions() {
        return this._clone(this[_options]);
    }

    getFieldsProperties() {
        return this._clone(this[_fieldsProperties]);
    }

    getAuthUser() {
        return globalThis.eyeInORMProvider.getAuthUser(this[_options]);
    }

    /**
     * PROTECTED Helpers
     */

    _hasDefaultValue(key) {
        return typeof this[_fieldsProperties][key].default !== `undefined`;
    }

    _getDefaultValue(key) {
        if (!this._hasDefaultValue(key)) {
            return undefined;
        }

        let defaultValue = undefined;
        if (typeof this[_fieldsProperties][key].default === `function`) {
            defaultValue = this[_fieldsProperties][key].default();
        } else {
            defaultValue = this[_fieldsProperties][key].default;
        }

        if (typeof defaultValue === `object`) {
            defaultValue = this.$reactive(key, defaultValue);
        }

        return defaultValue;
    }

    _checkValidity() {
        if (this[_options].updateMode) {
            return true;
        }

        for (const key in this[_fieldsProperties]) {
            if (this[_fieldsProperties][key].required) {
                if (typeof this[_fields][key] === `undefined`) {
                    throw new Error(`[${this.constructor.name}] "${key}" is required but is undefined.`);
                } else if (!this[_fieldsProperties][key].allowEmpty && TypeFactory.isEmpty(this[key])) {
                    throw new Error(`[${this.constructor.name}] "${key}" is required but is empty.`);
                }
            }
        }
    }

    _clone(value) {
        return JSON.parse(JSON.stringify(value));
    }

    _addDBOperation($operator, key, value) {
        for (const $operator of allOperators) {
            if (this[$operator].hasOwnProperty(key)) {
                delete this[$operator][key];
            }
        }

        this[$operator][key] = value;
    }

    _hasOperation(key) {
        for (const $operator of allOperators) {
            if (this[$operator].hasOwnProperty(key)) {
                return true;
            }
        }

        return false;
    }

    _applyFlags(value, flags = []) {
        for (const flag of flags) {
            switch (flag) {
                case F_TRIM:
                    value = value.trim();
                    break;
                case F_LOWER:
                    value = value.toLowerCase();
                    break;
            }
        }

        return value;
    }

    /**
     * STATIC METHODS
     */

    static _checkModelValidity() {
        if (!this.collection) {
            throw new Error(`Missing collection name`);
        }
    }

    static create(fields = {}, options = {updateMode: false}) {
        if (!options.updateMode) {
            return new this(fields, options);
        } else {
            const model = new this(fields, options);
            if (!fields.hasOwnProperty(`$operations`)) {
                model.$cancelAllChanges();
            }
            return model;
        }
    }

    static createInUpdateMode(fields = {}, options = {}) {
        options.updateMode = true;
        return this.create(fields, options);
    }

    /**
     * Retrieves the first Model that matches with the filter query
     * @param id {String} Model ID as String
     * @param [options] {Object}
     * @param [options.sort] {Object} List of fields to sort in order. Ex: {date: 1}. See https://www.mongodb.com/docs/drivers/node/current/fundamentals/crud/read-operations/sort/
     * @param [options.projection] {Object} List of fields to retrieve. Ex: {name: 1, dealerid: 1}. See https://www.mongodb.com/docs/manual/tutorial/project-fields-from-query-results/
     * @param [options.createIfEmpty] {Boolean}
     * @param [options.updateMode] {Boolean}
     * @returns {Promise<?Model>}
     */
    static async findById(id, options = {createIfEmpty: false, updateMode: true}) {
        this._checkModelValidity();
        const emptyModel = new this();
        const doc = await globalThis.eyeInORMProvider.findById(emptyModel, id, options);
        if (!doc) {
            return options.createIfEmpty ? this.create({}, options) : null;
        }
        return this.create(doc, options);
    }

    /**
     * Retrieves the first Model that matches with the filter query
     * @param filter {Object}
     * @param [options] {Object}
     * @param [options.sort] {Object} List of fields to sort in order. Ex: {date: 1}. See https://www.mongodb.com/docs/drivers/node/current/fundamentals/crud/read-operations/sort/
     * @param [options.projection] {Object} List of fields to retrieve. Ex: {name: 1, dealerid: 1}. See https://www.mongodb.com/docs/manual/tutorial/project-fields-from-query-results/
     * @param [options.createIfEmpty] {Boolean}
     * @param [options.updateMode] {Boolean}
     * @returns {Promise<?Model>}
     */
    static async findOne(filter, options = {createIfEmpty: false, updateMode: true}) {
        this._checkModelValidity();
        const model = new this();
        const doc = await globalThis.eyeInORMProvider.findOne(model, filter, options);

        if (!doc) {
            return options.createIfEmpty ? this.create({}, options) : null;
        }
        return this.create(doc, options);
    }

    /**
     * Retrieves an Array of Model that matches with the filter query
     * @param filter {Object}
     * @param [options] {Object}
     * @param [options.sort] {Object} List of fields to sort in order. Ex: {date: 1}. See https://www.mongodb.com/docs/drivers/node/current/fundamentals/crud/read-operations/sort/
     * @param [options.projection] {Object} List of fields to retrieve. Ex: {name: 1, dealerid: 1}. See https://www.mongodb.com/docs/manual/tutorial/project-fields-from-query-results/
     * @param [options.updateMode] {Boolean}
     * @returns {Promise<[Model]>}
     */
    static async find(filter, options = {updateMode: true}) {
        this._checkModelValidity();
        const model = new this();
        const docs = await globalThis.eyeInORMProvider.find(model, filter, options);
        return this.arrayFrom(docs, options);
    }

    static arrayFrom(arr, options = {updateMode: true}) {
        return arr.map(doc => this.create(doc, options));
    }

    /**
     * Retrieves an Array of Model that matches with the filter query.
     * Only returns the fields marked with preview = true
     * @param filter {Object}
     * @param [options] {Object}
     * @param [options.sort] {Object} List of fields to sort in order. Ex: {date: 1}. See https://www.mongodb.com/docs/drivers/node/current/fundamentals/crud/read-operations/sort/
     * @param [options.projection] {Object} List of fields to extract
     * @returns {Promise<[Object]>}
     */
    static async findDocExtracts(filter, options = {}) {
        Object.assign(options, {projection: (options.projection || this.extractFields)});
        return this.find(filter, options);
    }
}
