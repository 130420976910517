import CmsBlockListCategory from "@/helpers/editor/blocks/CmsBlockListCategory.js";
import CmsBlockListItem from "@/helpers/editor/blocks/CmsBlockListItem.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsComponentBorder from "@/helpers/editor/components/CmsComponentBorder.js";

export default class CmsBlockCmsList extends CmsBlock {
    static typeDef = {
        name: `List of Items|Liste d'éléments`,
        type: `cms-list`,
        glyph: `fa-list`,
        category: `widget`,
        isParent: true,
        cmsItem: true
    }

    get layoutPresets() {
        return [
            {
                id: `default`,
                name: `Default|Défaut`
            },
            {
                id: `cards`,
                name: `Cards|Cartes`
            }
        ]
    }

    constructor(data, page) {
        super(data, page);

        this.childrenReadonly = true;
        this.divider = new CmsComponentBorder(this.divider);

        if (!data) {
            this.divider.active = false;

            this.groupEnabled = false;
            this.groupBy = null;
            this.groupOrder = `asc`;

            this.sortEnabled = false;
            this.sortBy = `name`;
            this.sortOrder = `asc`;

            this.spacing = `10px`;
            this.columns = 2;
            this.mediaQuery.mobile.columns = 1;
            this.borderRadius = `var(--border-radius)`;
            this.addChild(new CmsBlockListItem());
            this.addChild(new CmsBlockListCategory());
        }
        if (this.children.length < 2) {
            this.addChild(new CmsBlockListCategory());
        }
    }
}
