import CmsBaseClass from "@/helpers/cms/CmsBaseClass.js";
import CmsLink from "@/helpers/cms/CmsLink.js";
import CmsMediaQuery from "@/helpers/editor/blocks/CmsMediaQuery.js";
import Vue from "vue";
import {tr} from "../../../vue-components/helpers/i18n.js";
import SeoHelper from "../../../vue-components/helpers/SeoHelper.js";

export default class CmsPage extends CmsBaseClass {
    static TYPES = {
        MAP: `map`,
        EDITABLE_PAGE: `blocks`,
        HTML: `html`,
        PAGE: `page`,
        TEMPLATE: `template`,
        LANDING: `landing`,
        SEARCH: `search`,
        CMS_ITEM_LIST: `cms-item-list`,
        CMS_ITEM_VIEW: `cms-item-view`
    }

    static BACKGROUNDS = {
        NONE: `none`,
        IMAGE: `image`,
        COLOR: `color`
        // GRADIENT: `gradient`
    }

    get ignoredFields() {
        return [`cms`, `itemTypeDef`, `item`, `itemList`, `mediaProxy`];
    }

    get autoGenerateId() {
        return false;
    }

    constructor(data, cms, route) {
        super(data);

        this.item = null;
        this.itemList = [];
        this.cms = cms;

        if (this.itemType) {
            this.itemTypeDef = cms.getTypeById(this.itemType);
        }

        if (this.mediaQuery && this.mediaQuery.expanded === undefined) {
            this.mediaQuery = null;
        }

        // object to hold mobile, kiosk and tablet variants
        this.mediaQuery = new CmsMediaQuery(this.mediaQuery);

        // The Media Proxy will use the component values or the one from mediaQuery with the device and preview
        this.mediaProxy = this.mediaQuery.createProxy(this);

        this.fallbackLink = new CmsLink(this.fallbackLink);

        if (!data) {
            this.type = CmsPage.TYPES.EDITABLE_PAGE;
            this.dealerid = null;

            // used for navigation
            this.name = {
                en: `New Page`,
                fr: `Nouvelle page`
            };
            this.glyph = null;
            this.template = `none`;
            this.homePage = false; // note that home page get "/" permalink
            this.permalink = {
                en: `new-page`,
                fr: `nouvelle-page`
            };

            this.itemType = ``;
            this.editable = true;

            this.websites = [];
            this.blocks = [];

            this.background = CmsPage.BACKGROUNDS.NONE;
            this.backgroundColor = `#ffffff`;
            this.backgroundImage = null;

            this.navigationBehavior = `visible`;
        } else {
            this.blocks = [];

            if (data.blocks) {
                for (let block of data.blocks) {
                    this.blocks.push(window.cmsFactory.new(block, this));
                }
            }
        }

        if (this.type === `cms-item-view`) {
            if (route.name === `cms-page-selector`) {
                this.item = cms.getItemByPermalink(cms.getRouteId(route.fullPath));
            } else {
                this.item = cms.getFirstItemByType(this.itemType);
            }
        }

        if (!this.meta) {
            // SEO
            this.meta = {
                title: {},
                description: {},
                keywords: {},
                searchVisibility: `visible`,
                socialImage: null,
                socialTitle: {
                    sameAsSEO: true
                },
                socialDescription: {
                    sameAsSEO: true
                }
            }
        }
        this.syncSocial();
    }

    syncSocial() {
        if (this.meta.socialTitle.sameAsSEO) {
            for (let lang in this.meta.title) {
                this.meta.socialTitle[lang] = this.meta.title[lang];
            }
        }
        if (this.meta.socialDescription.sameAsSEO) {
            for (let lang in this.meta.description) {
                this.meta.socialDescription[lang] = this.meta.description[lang];
            }
        }
    }

    addBlock(block) {
        this.blocks.push(block);
    }

    insertBlock(block, index) {
        this.blocks.splice(index + 1, 0, block);
    }

    duplicateBlock(block) {
        this.insertBlock(block.duplicate(), this.blocks.indexOf(block));
    }

    deleteBlock(block) {
        this.blocks.removeItem(block);
    }

    swapBlock(block, delta) {
        let index = this.blocks.indexOf(block);
        let newIndex = index + delta;
        if (newIndex >= 0 && newIndex < this.blocks.length) {
            let swaped = this.blocks[index];
            Vue.set(this.blocks, index, this.blocks[newIndex]);
            Vue.set(this.blocks, newIndex, swaped);
            // this.blocks[newIndex] = swaped;
        } else {
            console.error(`Invalid index: Impossible to move block`);
        }
    }

    async translate(from, to) {
        for (let block of this.blocks) {
            await block.translate(from, to);
        }
    }

    getMeta() {
        return SeoHelper.getMeta(tr(this.meta.title) || tr(this.name), tr(this.meta.description), tr(this.meta.keywords), `Eye-In Media`, this.meta.socialImage);
    }
}
