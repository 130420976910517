export default class EditorPadding {
    constructor(data) {
        if (!data) {
            this.top = 50;
            this.right = 20;
            this.bottom = 50;
            this.left = 20;
        } else {
            this.top = data.top;
            this.right = data.right;
            this.bottom = data.bottom;
            this.left = data.left;
        }
    }

    init(top = 0, right, bottom, left) {
        this.top = top;
        this.right = right || top;
        this.bottom = bottom || top;
        this.left = left || right || top;
    }

    leftMobile(mobilePadding = `0`) {
        return `${Math.min(this.left, parseInt(mobilePadding))}px`;
    }

    topMobile(mobilePadding = `0`) {
        return `${Math.min(this.top, parseInt(mobilePadding))}px`;
    }

    rightMobile(mobilePadding = `0`) {
        return `${Math.min(this.right, parseInt(mobilePadding))}px`;
    }

    bottomMobile(mobilePadding = `0`) {
        return `${Math.min(this.bottom, parseInt(mobilePadding))}px`;
    }

    toStringMobile(padding = `0`) {
        padding = parseInt(padding);
        return `${Math.min(this.top, padding)}px ${Math.min(this.right, padding)}px ${Math.min(this.bottom, padding)}px ${Math.min(this.left, padding)}px`;
    }

    toString() {
        return `${this.top}px ${this.right}px ${this.bottom}px ${this.left}px`;
    }
}
