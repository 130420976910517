import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsComponentIframe from "@/helpers/editor/components/CmsComponentIframe.js";

export default class CmsBlockIframe extends CmsBlock {
    static typeDef = {
        name: `Embed Website|Site intégré`,
        type: `iframe`,
        category: `widget`,
        glyph: `fa-globe`,
        isParent: false
    }

    constructor(data, page) {
        super(data, page);

        this.iframe = new CmsComponentIframe(this.iframe);

        if (!data) {
            this.padding.init(0);
            this.valid = false;
            this.iframe.height = 600;
        }
    }
}
