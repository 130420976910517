import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import {tr} from "../../../../vue-components/helpers/i18n.js";

export default class CmsBlockWordPage extends CmsBlock {
    static typeDef = {
        name: `Word Page|Page Word`,
        type: `word-page`,
        category: `preset`,
        glyph: `fa-file-lines`,
        isParent: false
    }

    get displayName() {
        return this.extractHtmlText(tr(this.content.text));
    }

    constructor(data, page) {
        super(data, page);

        this.content = new CmsComponentText(this.content);
        this.content.required = true;

        if (!data) {
            this.align = `left`;

            this.content.initText({en: `Edit this text`, fr: `Modifiez ce bloc de texte` });
        }
    }

    async translate(from, to) {
        await this.content.translate(from, to);
    }
}
