<template>
    <div class="e-loading-animation animated-hourglass fa-stack fa-4x">
        <i class="fa fa-stack-1x fa-hourglass-start"></i>
        <i class="fa fa-stack-1x fa-hourglass-half"></i>
        <i class="fa fa-stack-1x fa-hourglass-end"></i>
        <i class="fa fa-stack-1x fa-hourglass-end"></i>
        <i class="fa fa-stack-1x fa-hourglass-o"></i>
    </div>
</template>

<script>
export default {
    name: `e-loading-animation`
}
</script>

<style lang="scss" scoped>
$animationSpeed: 0.5;
//$colors: #ffc400, #ffc400, #ffab00, #ffc400, #006064;

.animated-hourglass {
    opacity: 1;
    //color: #6a1b9a;
    $_animationSpin: hourglass-spin ($animationSpeed * 4s) ease-out infinite;

    i {
        opacity: 0;
        animation: hourglass ($animationSpeed * 4s) ease-in infinite,
        $_animationSpin;
    }

    @for $i from 1 through 4 {
        & > i:nth-child(#{$i}) {
            //color: nth($colors, $i);
            opacity: 0.05 * $i;
            animation-delay: $animationSpeed * ($i - 1) * 1s, 0s;
        }
    }

    & > i:nth-child(4) {
        animation: hourglass-end ($animationSpeed * 4s) ease-in infinite,
        $_animationSpin;
    }

    & > i:nth-child(5) {
        //color: nth($colors, 5);
        opacity: 1;
        animation: $_animationSpin;
    }
}

@keyframes hourglass {
    0% {
        opacity: 1
    }
    24% {
        opacity: 0.9
    }
    50% {
        opacity: 0
    }
}

@keyframes hourglass-end {
    0% {
        opacity: 0
    }
    70% {
        opacity: 0
    }
    75% {
        opacity: 1
    }
    100% {
        opacity: 1
    }
}

@keyframes hourglass-spin {
    75% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
</style>
