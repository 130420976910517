import CmsComponentImage from "@/helpers/editor/components/CmsComponentImage.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";

export default class CmsBlockListCategory extends CmsBlock {
    static typeDef = {
        name: `List Category|Catégorie de liste`,
        type: `list-category`,
        category: `widget`,
        glyph: `fa-list-tree`,
        isParent: false,
        hidden: true
    }

    constructor(data, page) {
        super(data, page);

        this.image = new CmsComponentImage(this.image);

        this.label = new CmsComponentText(this.label);

        if (!data) {
            this.image.enabled = false;

            this.label.enableBinding();
            this.label.textField = `name`;
            this.label.required = true;
        }
    }
}
