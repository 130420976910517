import CmsComponentButton from "@/helpers/editor/components/CmsComponentButton.js";
import CmsComponentImage from "@/helpers/editor/components/CmsComponentImage.js";
import CmsComponentLink from "@/helpers/editor/components/CmsComponentLink.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";

export default class CmsBlockListItem extends CmsBlock {
    static typeDef = {
        name: `List Item|Elément de liste`,
        type: `list-item`,
        category: `widget`,
        glyph: `fa-id-card`,
        isParent: false,
        hidden: true
    }

    get layoutPresets() {
        return [
            {
                id: `default`,
                name: `Default|Défaut`
            },
            {
                id: `row-left`,
                name: `Row Left|Ligne à gauche`
            },
            {
                id: `row-right`,
                name: `Row Right|Ligne à droite`
            }
        ]
    }

    constructor(data, page) {
        super(data, page);

        this.link = new CmsComponentLink(this.link);
        this.image = new CmsComponentImage(this.image);

        this.title = new CmsComponentText(this.title);
        this.subtitle = new CmsComponentText(this.subtitle);
        this.description = new CmsComponentText(this.description);

        this.button = new CmsComponentButton(this.button);

        if (!data) {
            this.image.enableBinding();
            this.image.imageField = `image`;

            this.title.initText({ en: `Title`, fr: `Titre`}, `h4`);
            this.title.enableBinding();
            this.title.textField = `name`;

            this.subtitle.initText({ en: `Subtitle`, fr: `Sous-titre`});
            this.subtitle.enableBinding();
            this.subtitle.textField = `secondaryField`;

            this.description.active = false;
            this.description.initText({ en: `Description`, fr: `Description`});
            this.description.enableBinding();

            this.button.active = false;
            this.button.initText({en: `Button`, fr: `Bouton`});
            this.button.enableBinding();

            this.borderRadius = `var(--border-radius)`;
        }
    }
}
