import EditorPadding from "@/helpers/cms/EditorPadding.js";
import CmsComponentButton from "@/helpers/editor/components/CmsComponentButton.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import {tr} from "../../../../vue-components/helpers/i18n.js";

export default class CmsBlockButton extends CmsBlock {
    static typeDef = {
        name: `Navigation|Navigation`,
        type: `navigation`,
        category: `layout`,
        glyph: `fa-browser`,
        isParent: false
    }

    constructor(data, page) {
        super(data, page);

        this.title = new CmsComponentText(this.title);

        this.subtitle = new CmsComponentText(this.subtitle);

        this.backButton = new CmsComponentButton(this.backButton);
        this.nextButton = new CmsComponentButton(this.nextButton);

        if (!data) {
            this.padding = new EditorPadding({left: 0, top: 0, right: 0, bottom: 0});
            this.height = 40;// px
            this.buttonFill = true;
            this.buttonWidth = 15;// %

            this.title.initText({en: `Title`, fr: `Titre`}, `h4`);

            this.subtitle.initText({en: `Subtitle`, fr: `Sous-titre`});
            this.subtitle.active = false;

            this.backButton.initText({en: `Back`, fr: `Précédent`});
            this.backButton.leftIcon = `fa-chevron-left`;

            this.nextButton.initText({en: `Next`, fr: `Suivant`});
            this.nextButton.rightIcon = `fa-chevron-right`;
        }
    }

    async translate(from, to) {
        await this.title.translate(from, to);
        await this.subtitle.translate(from, to);
        await this.description.translate(from, to);
        await this.backButton.translate(from, to);
        await this.nextButton.translate(from, to);
    }
}
