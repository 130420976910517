export default class Translation {
    constructor(value) {
        if (value) {
            if (!(value instanceof Translation) && typeof value !== `object`) {
                throw new Error(`Translation type need an object`)
            }

            Object.assign(this, value);
        } else {
            this.en = ``;
        }
    }

    isEmpty() {
        return Object.values(this).every(v => !v);
    }
}
