import moment from "moment"

export function getLanguage() {
    let language = localStorage.getItem(`language`);
    if (!language) {
        language = navigator.language.substring(0, 2) === `fr` ? `fr` : `en`;
        localStorage.setItem(`language`, language);
    }

    return language;
}

export function changeLanguage() {
    let language = localStorage.getItem(`language`);
    if (language === `fr`) {
        language = `en`;
    } else {
        language = `fr`;
    }

    moment.locale(language);
    localStorage.setItem(`language`, language);
    return language;
}

export function isFrench() {
    return getLanguage() === `fr`;
}

export function isEnglish() {
    return getLanguage() === `en`;
}

export function setLanguage(language) {
    if (language !== `en` && language !== `fr`) {
        console.error(`invalid language ${language}`);
        return;
    }
    moment.locale(language);
    localStorage.setItem(`language`, language);
    return language;
}

export function compareTranslation(a, b) {
    if (a && b) {
        return tr(a).localeCompare(tr(b));
    } else {
        return b - a;
    }
}

export function translate(values, language) {
    if (!values) {
        return ``;
    }
    if (!language) {
        language = getLanguage();
    }

    const translations = getTranslations(values);
    if (translations === null) {
        return values;
    } else {
        if (language === `fr`) {
            if (translations.fr && translations.fr.length > 0) {
                return translations.fr;
            }
        } else {
            if (translations.en && translations.en.length > 0) {
                return translations.en;
            } else if (!translations.en && translations.fr && translations.fr.length > 0) {
                return translations.fr;
            }
        }
        if (translations.default) {
            return translations.default;
        }
        return translations.en;
    }
}

export const tr = translate;

export function getTranslations(values) {
    if (values === null || values === undefined || values === ``) {
        return null;
    }

    if (Array.isArray(values)) {
        if (!values || values.length < 2) {
            return null;
        }
        return {
            fr: values[1],
            en: values[0]
        };
    } else if (values[`en`] || values[`fr`] || values[`default`]) {
        return values;
    } else if (typeof values === `string` && isValidJSON(values)) {
        return JSON.parse(values);
    } else if (typeof values === `string` && values.indexOf(`|`) >= 0) {
        const translations = values.split(`|`);
        if (translations.length < 2) {
            return null;
        }
        return {
            fr: translations[1],
            en: translations[0]
        };
    } else if (typeof values === `string`) {
        return null;
    } else {
        return {
            fr: ``,
            en: ``
        };
    }
}

function isValidJSON(str) {
    try {
        const json = JSON.parse(str);
        // ensure the end result is a valid object and not just a string or a number
        return typeof json === `object`;
    } catch (e) {
        return false;
    }
}
