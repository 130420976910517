import MapBaseClass from "./MapBaseClass.js";
import MapFloor from "./MapFloor.js";
import MapItem from "./MapItem.js";
import MapLink from "./MapLink.js";

export default class MapPlan extends MapBaseClass {
    constructor(data) {
        super(data);

        // for autocompletion
        this.floors = [];
        this.items = [];
        this.links = [];
        for (let i = 0; i < data.floors.length; i++) {
            this.floors.push(new MapFloor(data.floors[i]));
        }
        for (let i = 0; i < data.items.length; i++) {
            let mapItem = new MapItem(data.items[i], this);
            mapItem.index = `n${i}`;
            this.items.push(mapItem);
        }
        // Always initialize links after items
        for (let i = 0; i < data.links.length; i++) {
            let mapLink = new MapLink(data.links[i], this);
            mapLink.index = `l${i}`;
            this.links.push(mapLink);
        }

        if (this.floors.length > 1) {
            this.sortFloors();
        }
    }

    getFloorById(id) {
        return this.floors.find(f => f.id === id);
    }

    getItemById(id) {
        return this.items.find(i => i.id === id);
    }

    getItemsByCmsItemId(cmsItemId) {
        return this.items.filter(i => i.integrations.cms.itemId === cmsItemId);
    }

    getItemsByType(type) {
        return this.items.filter(i => i.type === type);
    }

    getItemByQRCode(qrCode) {
        return this.items.find(i => i.qrCode === qrCode);
    }

    getItemByPermalink(permalink) {
        return this.items.find(i => i.matchPermalink(permalink));
    }

    getItemsByPermalink(permalink) {
        return this.items.filter(i => i.matchPermalink(permalink));
    }

    getMainFloor() {
        if (this.floors.length === 0) {
            return null;
        } else if (this.floors.length === 1) {
            return this.floors[0];
        } else {
            const floor = this.floors.find(floor => {
                return floor.isMainFloor === true;
            });
            if (floor) {
                return floor;
            } else {
                return this.floors[0];
            }
        }
    }

    addFloor(floor) {
        this.floors.push(floor);
        this.sortFloors();
    }

    addLink(link) {
        this.links.push(link);
    }

    addItem(link) {
        this.items.push(link);
    }

    sortFloors() {
        this.floors.sort((a, b) => {
            return b.floor - a.floor;
        });
    }

    toJSON() {
        let data = {
            floors: [],
            items: [],
            links: []
        };
        for (let key in this) {
            switch (key) {
                case `floors`:
                    for (let floor of this.floors) {
                        if (!floor.deleted) {
                            data.floors.push(floor.toJSON());
                        }
                    }
                    break;
                case `items`:
                    for (let item of this.items) {
                        data.items.push(item.toJSON());
                    }
                    break;
                case `links`:
                    for (let link of this.links) {
                        data.links.push(link.toJSON());
                    }
                    break;
                default:
                    if (typeof this[key] !== `function`) {
                        data[key] = this[key];
                    }
                    break;
            }
        }
        return data;
    }
}
