<template>
    <div class="website-page-container cms-website" :class="[containerClass, { 'has-virtual-keyboard': virtualKeyboard }]" :style="website.styleVariables">
        <div class="website-page" :class="[pageClass, { 'no-menu-padding': noMenuPadding, 'centered': centered }]">
            <slot></slot>
        </div>
        <slot name="keyboard"></slot>
    </div>
</template>

<script>
    export default {
        name: `website-page`,
        components: {},
        props: {
            containerClass: {type: String, default: ``},
            pageClass: {type: String, default: ``},
            virtualKeyboard: {type: Boolean},
            centered: {type: Boolean},
            noMenuPadding: {type: Boolean},
            noFooter: {type: Boolean},
            loading: {type: Boolean}
        },
        computed: {
            isEmbed() {
                return this.$route.query.embed !== undefined;
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../assets/style/website/website.scss";

.website-page-container {
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .website-page {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;

        &.no-menu-padding {
            padding-top: 0;
        }

        &.centered {
            align-items: center;
            justify-content: center;
        }
    }

}
</style>
