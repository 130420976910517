import CmsLink from "@/helpers/cms/CmsLink.js";
import CmsComponent from "@/helpers/editor/components/CmsComponent.js";

export default class CmsComponentGlyph extends CmsComponent {

    get allowBinding() {
        return true;
    }

    get allowMediaQuery() {
        return true;
    }

    type = `glyph`

    constructor(data) {
        super(data);

        this.link = new CmsLink(this.link);

        if (!data) {
            this.glyph = ``;
            this.size = 32;

            this.color = `inherit`;
            this.spacing = 0;

            // Item Binding
            this.glyphField = null;
        }
    }
}
