import CmsComponentButton from "@/helpers/editor/components/CmsComponentButton.js";
import CmsComponentText from "@/helpers/editor/components/CmsComponentText.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import {tr} from "../../../../vue-components/helpers/i18n.js";

export default class CmsBlockTitle extends CmsBlock {
    static typeDef = {
        name: `Title|Titre`,
        type: `title`,
        category: `preset`,
        glyph: `fa-text`,
        isParent: false
    }

    get displayName() {
        if (this.item) {
            return tr(this.item.name);
        } else {
            return this.extractHtmlText(tr(this.title.text));
        }
    }

    constructor(data, page) {
        super(data, page);

        this.title = new CmsComponentText(this.title);

        this.subtitle = new CmsComponentText(this.subtitle);

        this.description = new CmsComponentText(this.description);

        this.button = new CmsComponentButton(this.button);

        if (!data) {
            this.title.initText({en: `Title`, fr: `Titre`}, `h1`);
            this.subtitle.initText({en: `Description`, fr: `Description`}, `h4`);
            this.description.initText({en: `Description`, fr: `Description`});

            this.button.active = false;
            this.button.initText({en: `Button`, fr: `Bouton`});
        }
    }

    async translate(from, to) {
        await this.title.translate(from, to);
        await this.subtitle.translate(from, to);
        await this.description.translate(from, to);
        await this.button.translate(from, to);
    }
}
