import CmsComponentImage from "@/helpers/editor/components/CmsComponentImage.js";
import CmsComponentNavigationLinks from "@/helpers/editor/components/CmsComponentNavigationLinks.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";

export default class CmsBlockMenu extends CmsBlock {
    static typeDef = {
        name: `Header|Menu`,
        type: `menu`,
        category: `navigation`,
        glyph: `fa-browser`,
        isParent: false,
        hidden: true
    }

    static POSITIONS = {
        FIXED: `fixed`,
        STATIC: `static`,
        RELATIVE: `relative`
    }
    static ANCHORS = {
        TOP: `top`,
        RIGHT: `right`,
        BOTTOM: `bottom`,
        LEFT: `left`,
    }

    // get layoutPresets() {
    //     return [
    //         {
    //             id: `default`,
    //             name: `Default|Défaut`
    //         },
    //         {
    //             id: `row-left`,
    //             name: `Row Left|Ligne à gauche`
    //         },
    //         {
    //             id: `row-right`,
    //             name: `Row Right|Ligne à droite`
    //         }
    //     ]
    // }

    constructor(data) {
        super(data);

        this.logo = new CmsComponentImage(this.logo);

        this.links = new CmsComponentNavigationLinks(this.links);
        this.leftLinks = new CmsComponentNavigationLinks(this.leftLinks);
        this.rightLinks = new CmsComponentNavigationLinks(this.rightLinks);

        if (!data) {
            this.padding.top = 10;
            this.padding.left = 10;
            this.padding.bottom = 10;
            this.padding.right = 10;

            this.position = CmsBlockMenu.POSITIONS.FIXED;
            this.anchor = CmsBlockMenu.ANCHORS.TOP;

            this.layout = `default`;
            this.links.layout = `horizontal`;
            this.links.spacing = `0px`;
            this.links.useMenuNavigation = true;

            this.leftLinks.layout = `horizontal`;
            this.leftLinks.spacing = `0px`;
            this.leftLinks.display = `icon`;

            this.rightLinks.layout = `horizontal`;
            this.rightLinks.spacing = `0px`;
            this.rightLinks.display = `icon`;

            this.logo.maxWidth = 150;
            this.logo.maxHeight = 40;

            this.size = 80;
            this.mediaQuery.kiosk.height = 100;
            this.mediaQuery.mobile.height = 60;
        } else {
            this.renameField(`height`, `size`);
        }
    }

    get orientation() {
        if (this.mediaProxy.anchor === CmsBlockMenu.ANCHORS.LEFT || this.mediaProxy.anchor === CmsBlockMenu.ANCHORS.RIGHT) {
            return `vertical`;
        } else {
            return `horizontal`;
        }
    }
}
