import {tr} from "../../../vue-components/helpers/i18n.js";
import MapBaseClass from "./MapBaseClass.js";

export default class MapFloor extends MapBaseClass {
    constructor(data) {
        super(data);

        if (this.level === undefined && this.floor !== undefined) {
            this.level = this.floor;
        }
    }

    getName() {
        if (tr(this.label)) {
            return tr(this.label);
        } else {
            return tr(this.name);
        }
    }
}
