import MapPlan from "@/helpers/maps/MapPlan.js";

export default {
    namespaced: true,
    state: () => ({
        map: null,
        floor: null,
        debug: {
            enabled: false,
            pano: true,
            map: false,
            links: false,
            path: true,
            svg: true
        },
        width: 1920,
        height: 1080
    }),
    getters: {},
    actions: {},
    mutations: {
        setMap(state, map) {
            state.map = new MapPlan(map);
        },
        setFloor(state, floor) {
            state.floor = floor;
        }
    }
}
