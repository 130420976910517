import CmsBlockFactory from "@/helpers/editor/blocks/CmsBlockFactory.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import "../vue-components/helpers/vue-mixin.js";
import './vue-global';
import '../vue-components/helpers/vue-filters.js';
import VHotkey from 'v-hotkey';
import 'moment/locale/fr';
import VueIziToast from 'vue-izitoast'

import {
    AlertPlugin,
    BadgePlugin,
    ButtonPlugin,
    ButtonGroupPlugin,
    CardPlugin,
    CollapsePlugin,
    CarouselPlugin,
    DropdownPlugin,
    EmbedPlugin,
    FormPlugin,
    FormInputPlugin,
    FormGroupPlugin,
    FormCheckboxPlugin,
    FormRadioPlugin,
    FormSpinbuttonPlugin,
    FormSelectPlugin,
    FormTagsPlugin,
    FormTextareaPlugin,
    ImagePlugin,
    InputGroupPlugin,
    LayoutPlugin,
    ListGroupPlugin,
    ModalPlugin,
    MediaPlugin,
    NavbarPlugin,
    NavPlugin,
    OverlayPlugin,
    PaginationPlugin,
    PopoverPlugin,
    ProgressPlugin,
    SidebarPlugin,
    TabsPlugin,
    ToastPlugin,
    TooltipPlugin,
    VBModalPlugin
} from 'bootstrap-vue'

import VueMeta from 'vue-meta'
import WebsitePage from '/src/layout/website-page.vue'
import Vue2TouchEvents from 'vue2-touch-events'
import ClientSideORMProvider from "../vue-components/helpers/ClientSideORMProvider.js";

globalThis.eyeInORMProvider = ClientSideORMProvider;

Vue.config.productionTip = false;

Vue.use(AlertPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(CardPlugin);
Vue.use(CarouselPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(EmbedPlugin);
Vue.use(FormPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSpinbuttonPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTagsPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(ImagePlugin);
Vue.use(InputGroupPlugin);
Vue.use(LayoutPlugin);
Vue.use(ListGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(MediaPlugin);
Vue.use(NavbarPlugin);
Vue.use(NavPlugin);
Vue.use(OverlayPlugin);
Vue.use(PaginationPlugin);
Vue.use(PopoverPlugin);
Vue.use(ProgressPlugin);
Vue.use(SidebarPlugin);
Vue.use(TabsPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
Vue.use(VBModalPlugin);

Vue.use(VueIziToast);

Vue.use(Vue2TouchEvents, {
    touchClass: ``,
    tapTolerance: 10,
    swipeTolerance: 30,
    longTapTimeInterval: 400
});
Vue.use(VueMeta);
Vue.use(VHotkey);

Vue.component(`website-page`, WebsitePage);

window.cmsFactory = new CmsBlockFactory();

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount(`#app`);
