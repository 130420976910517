import Network from "../../vue-components/helpers/Network.js";

export default {
    namespaced: true,
    state: () => ({
        fonts: null,
        loadedList: [],
        previewList: [],
        loading: false
    }),
    getters: {
        loadedFonts(state) {
            return state.loadedList;
        },
        previewList(state) {
            return state.previewList;
        }
    },
    actions: {
        async getFonts({state, commit}) {
            if (state.fonts) {
                return state.fonts;
            }
            if (state.loading) {
                return;
            }
            state.loading = true;
            try {
                const response = await Network.get(`/api/google/fonts/list`)
                commit(`setFonts`, response.data);
            } catch (e) {
                console.error(e);
            }
            state.loading = false;
        },
    },
    mutations: {
        setLoaded(state, font) {
            state.loadedList.push(font);
        },
        setPreviewLoaded(state, font) {
            state.previewList.push(font);
        },
        setFonts(state, fonts) {
            state.fonts = fonts;
        }
    }
}
