import CmsComponent from "@/helpers/editor/components/CmsComponent.js";

export default class CmsComponentShadow extends CmsComponent {
    type = `shadow`

    get allowMediaQuery() {
        return true;
    }

    constructor(data) {
        super(data);

        if (!data) {
            this.color = `#00000005`;
            this.blur = `25px`;
            this.spread = `0`;
            this.offsetX = 0;
            this.offsetY = 0;
        }
    }

    toCss() {
        return `${this.mediaProxy.offsetX}px ${this.mediaProxy.offsetY}px ${this.mediaProxy.blur} ${this.mediaProxy.spread} ${this.mediaProxy.color}`;
    }
}
